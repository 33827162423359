<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="b(modifiers)">
    <div v-if="mainImage"
         :class="b('image-wrapper')"
    >
      <component v-bind="link"
                 :is="link ? 'a' : 'div'"
                 :class="b('image-link')"
      >
        <e-picture v-if="mainImage"
                   v-bind="mainImage"
                   :ratio="460 / 300"
                   :sizes="imageSizes"
        />
      </component>
    </div>
    <div :class="b('content-wrapper', { border })">
      <div :class="[b('headline-wrapper'), headlineClasses]">
        <component v-bind="link"
                   :is="link ? 'a' : 'div'"
                   :class="b(link ? 'link' : 'icon-wrapper')"
        >
          <component
            :is="headlineTag"
            :class="b('headline')"
          >

            <span v-if="headlineTag && headline" v-html="headline"></span>
            <e-icon v-if="link"
                    :class="b('arrow')"
                    icon="i-arrow-full--right"
            />
          </component>
        </component>
      </div>
      <div v-if="text"
           v-html="text"
           v-protect-mailto
           :class="b('text-wrapper')"
           class="wysiwyg"
      >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import ePicture from '@/elements/e-picture.vue';
  import eIcon from '@/elements/e-icon.vue';
  import { ColumnWidth } from '@/components/c-row-container.vue';
  import { LinkWithModalAttribute } from '@/helpers/cms-link-attributes';
  import { Modifiers } from '@/plugins/vue-bem-cn/src/globals';
  import { ImageSrcset } from '@/types/image';
  import { ImageSizes } from '@/types/sizes';
  // interface Setup {}

  // interface Data {}

  export enum ImagePosition {
    Left = 'left',
    Right = 'right',
    Top = 'top',
  }

  /**
  * Renders an image and text in different variants. The position of the image is flexible and optional – so you can use it also as a simple text component.
  */
  export default defineComponent({
    name: 'c-marketing-paragraph',

    components: {
      ePicture,
      eIcon,
    },

    props: {
      /**
       * Expects the tiles text.
       */
      text: {
        type: String,
        required: true,
      },

      /**
       * Allows to set the headline.
       */
      headline: {
        type: String,
        default: undefined,
      },

      /**
       * Allows to set the headline tag.
       */
      headlineTag: {
        type: String,
        default: 'span',
      },

      /**
       * Allows to set additional color variant class.
       */
      headlineClasses: {
        type: String,
        default: undefined,
      },

      /**
       * Allows to set the link.
       */
      link: {
        type: Object as PropType<LinkWithModalAttribute | null>,
        default: undefined,
      },

      /**
       * Allows to set the image.
       */
      mainImage: {
        type: Object as PropType<ImageSrcset>,
        default: undefined,
      },

      /**
       * Allows to set the image position.
       */
      imagePosition: {
        type: String as PropType<ImagePosition>,
        default: undefined,
      },

      /**
       * Allows to set the border.
       */
      border: {
        type: Boolean,
        default: false,
      },

      /**
       * Allows to set the column width.
       */
      width: {
        type: Number as PropType<ColumnWidth>,
        default: 12,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data() {
    //   return {};
    // },

    computed: {
      modifiers(): Modifiers {
        return {
          hasImage: !!this.mainImage,
          imagePosition: this.imagePosition,
          columnWidth: `col-sm-${this.width}`,
        };
      },

      /**
       * Gets the correct image sizes based on the component width.
       */
      imageSizes(): ImageSizes {
        switch (this.width) {
          case ColumnWidth.Col6:
            return {
              xxs: 450,
              xs: 737,
              sm: 478,
              md: 565,
              lg: 685,
            };

          default: // COL12
            return {
              xxs: 450,
              xs: 737,
              sm: 984,
              md: 1160,
              lg: 1400,
            };
        }
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-marketing-paragraph {
    $this: &;

    @include mixins.media(sm) {
      grid-column: span 12;
    }

    &__content-wrapper {
      &--border {
        @include mixins.media(sm) {
          padding-bottom: variables.$spacing--20;
          border-bottom: 4px solid variables.$color-grayscale--0;
        }
      }
    }

    &__headline-wrapper.coltogum-rot {
      #{$this}__headline {
        color: variables.$color-coltogum--red;
      }

      #{$this}__link {
        &:focus,
        &:hover {
          border-color: variables.$color-coltogum--red;
        }
      }
    }

    &__headline-wrapper.coltogum-blau {
      #{$this}__headline {
        color: variables.$color-coltogum--blue;
      }

      #{$this}__link {
        &:focus,
        &:hover {
          border-color: variables.$color-coltogum--blue;
        }
      }
    }

    &__headline-wrapper.coltogum-gruen {
      #{$this}__headline {
        color: variables.$color-coltogum--green;
      }

      #{$this}__link {
        &:focus,
        &:hover {
          border-color: variables.$color-coltogum--green;
        }
      }
    }

    &__headline {
      @include mixins.font(variables.$font-size--20, variables.$line-height--20);

      margin: 0;
      color: variables.$color-heading-2;

      @include mixins.media(sm) {
        @include mixins.font(variables.$font-size--25, variables.$line-height--25);
      }
    }

    &__link,
    &__headline,
    &__icon-wrapper {
      display: flex;
      gap: variables.$spacing--10;
      align-items: center;
    }

    &__link {
      @include mixins.link--secondary();

      display: inline-block;
    }

    &__arrow {
      width: 1em;
      height: 1em;
    }

    &--column-width-col-sm-4,
    &--column-width-col-sm-6 {
      @include mixins.media(sm) {
        display: grid;
        grid-template-rows: 1fr;
      }
    }

    &--column-width-col-sm-4#{$this}--has-image,
    &--column-width-col-sm-6#{$this}--has-image {
      @include mixins.media(sm) {
        grid-template-rows: auto 1fr;
      }
    }

    &--column-width-col-sm-4 {
      @include mixins.media(sm) {
        grid-column: span 6;

        #{$this}__headline {
          @include mixins.font(variables.$font-size--20, variables.$line-height--20);
        }
      }

      @include mixins.media(md) {
        grid-column: span 4;
      }
    }

    &--column-width-col-sm-6 {
      @include mixins.media(sm) {
        grid-column: span 6;

        #{$this}__headline {
          @include mixins.font(variables.$font-size--30, variables.$line-height--30);
        }
      }
    }

    &--image-position-left,
    &--image-position-right {
      @include mixins.media(sm) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
      }

      #{$this}__image-wrapper {
        @include mixins.media(sm) {
          grid-column: span 6;
          margin-bottom: 0;
          padding-top: 0;
          padding-right: variables.$spacing--15;
        }
      }

      #{$this}__content-wrapper {
        @include mixins.media(sm) {
          grid-column: span 6;
          padding-top: variables.$spacing--0;
        }
      }
    }

    &--image-position-right {
      #{$this}__image-wrapper {
        @include mixins.media(sm) {
          order: 2;
          padding-right: 0;
          padding-left: variables.$spacing--15;
        }
      }

      #{$this}__content-wrapper {
        @include mixins.media(sm) {
          order: 1;
          padding-right: variables.$spacing--15;
          padding-left: 0;
        }
      }
    }

    &__image-wrapper {
      margin-bottom: variables.$spacing--30;

      img {
        max-width: 100%;
      }
    }

    &__image-link {
      display: block;
    }
  }
</style>
