import { Plugin, reactive } from 'vue';

export interface Options {
  title?: string | null;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

interface Confirmation {
  instance: null | {
    resolve(): void;
    reject(): void;
    options?: Options;
  };
}

export const confirmation: Confirmation = reactive({
  instance: null,
});

const plugin: Plugin = {
  install(app) {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$confirmation = (options?: Options) => new Promise<void>((resolve, reject) => {
      confirmation.instance = { resolve, reject, options };
    }).finally(() => {
      confirmation.instance = null;
    });
  },
};

export default plugin;
