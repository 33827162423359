<template>
  <div v-if="show" :class="b()">
    <h2 :class="b('section-title')">
      {{ $t('l-product-detail.variantTableTitle') }}

      <button
        v-if="hasActiveFilters"
        :class="b('reset-all-button')"
        type="button"
        @click="onClickResetAll"
      >
        {{ $t('c-product-result.buttonResetFilter') }}
        <e-icon icon="i-close" size="15" />
      </button>
    </h2>
    <div :class="b('variant-filters')">
      <c-collapse v-if="!$viewport.isMd && primaryVariantFilters.length"
                  :class="b('filter-collapse')"
                  :padding="700"
      >
        <template #head="{ toggle, isOpen }">
          <div :class="b('filter-head')">
            <button :class="b('filter-toggle', { isOpen })"
                    type="button"
                    @click="toggle"
            >
              <e-icon icon="i-arrow--down" size="25" />
              {{ $t('global.filters') }}
            </button>
          </div>
        </template>

        <c-filter :facets="primaryVariantFilters"
                  :loading="productDetailStore.runningRequests.apiLoadFilteredProductVariants"
                  @update="onVariantFilterUpdate"
        />
      </c-collapse>
      <c-filter v-else-if="primaryVariantFilters.length"
                :facets="primaryVariantFilters"
                :loading="productDetailStore.runningRequests.apiLoadFilteredProductVariants"
                @update="onVariantFilterUpdate"
      />
      <c-filter v-if="section1VariantFilters.length"
                :facets="section1VariantFilters"
                :loading="productDetailStore.runningRequests.apiLoadFilteredProductVariants"
                @update="onVariantFilterUpdate"
      />
    </div>

    <button
      v-if="hasActiveFilters"
      :class="b('reset-all-button', { mobile: true })"
      type="button"
      @click="onClickResetAll"
    >
      <e-icon icon="i-close" size="15" />
      {{ $t('c-product-result.buttonResetFilter') }}
    </button>

    <e-loader v-if="productDetailStore.runningRequests.apiLoadFilteredProductVariants && !productVariants.length" />
    <div v-else :class="b('table-wrapper')">
      <e-table :columns="variantTableColumns"
               :items="productVariants"
               has-detail-rows
               only-one-detail-row
               html-headings
               @detail-toggled="onDetailToggle"
      >
        <template #featureValue="{ item, column }">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="item.features[column.key]"></span>
        </template>

        <template #productCode="{ item, column }">
          <div :class="b('variant-product-code')">
            <span :class="b('variant-code', { productCode: true })">
              {{ item.product[column.key] }}
            </span>
            <span v-if="item.product.customerProductId"
                  :class="b('variant-code', { customerCode: true })"
            >
              {{ item.product.customerProductId }}
            </span>
            <e-icon v-if="item.isPromoted"
                    :class="b('variant-promoted-flag')"
                    icon="i-rebuy"
                    size="16"
            />
            <e-icon v-if="sessionStore.flags.showCadData && item.product.hasCADData"
                    icon="i-cad"
                    size="16"
            />
          </div>
        </template>

        <template #liquidationOrDiscount="{ item }">
          <c-stock-icon v-if="item.product.inLiquidation"
                        :class="b('liquidation-icon')"

                        :label="$t('global.productStock.liquidation')"
                        icon="i-liquidation"
                        hide-label
          />
          <c-stock-icon v-else-if="item.product.hasSpecialPrice"
                        :class="b('special-price-icon')"

                        :label="$t('global.productStock.specialPrice')"
                        icon="i-discount"
                        hide-label
          />
        </template>

        <template #grossAndSpecialPrice="{ item }">
          <template v-if="isProduct(item.product)">
            <template v-if="!$viewport.isSm">
              <c-stock-icon v-if="item.product.inLiquidation"
                            :class="b('liquidation-icon', { mobile: true })"
                            :label="$t('global.productStock.liquidation')"
                            icon="i-liquidation"
                            hide-label
              />
              <c-stock-icon v-else-if="item.product.hasSpecialPrice"
                            :class="b('special-price-icon', { mobile: true })"
                            :label="$t('global.productStock.specialPrice')"
                            icon="i-discount"
                            hide-label
              />
            </template>
            <div v-if="!sessionStore.getShowOnlyRecommendedRetailPrice">
              <div v-price="item.product.price?.value"
                   :class="b('variant-price', { strikeThrough: !!item.product.specialPrice })"
              ></div>
              <div v-price="item.product.specialPrice?.value" :class="b('variant-price')"></div>
            </div>
          </template>
        </template>

        <template #price="{ item }">
          <template v-if="sessionStore.getShowOnlyRecommendedRetailPrice">
            <div v-if="item.product.recommendedRetailPrice"
                 v-price="item.product.recommendedRetailPrice?.value"
                 :class="b('variant-price')"
            ></div>
          </template>
          <template v-else-if="isProduct(item.product) && customerPriceStore.getCustomerPriceByProduct(item.product)">
            <div v-price="customerPriceStore.getCustomerPriceByProduct(item.product)?.value"
                 :class="b('variant-price')"
            ></div>
          </template>
        </template>

        <template #actions="{ item }">
          <div v-if="isProduct(item.product)" :class="b('variant-actions')">
            <c-product-stock :product="item.product"
                             :show-indicators="['stock']"
                             variant="icons-only"
            />
            <div v-if="item.product?.configurable && item.product.configurationUrl" :class="b('configuration-button')">
              <e-button
                :href="addContextPathToUrl(item.product.configurationUrl)"
                variant="secondary"
                height="200"
                spacing="0"
              >
                {{ $t('global.configuration') }}
              </e-button>
            </div>
            <c-add-to-cart v-else
                           :product="item.product"
                           :gtm-list-name="ListName.VariantList"
                           show-product-actions
            />
          </div>
        </template>

        <template #detailRow="{ item }">
          <e-loader v-if="productDetailStore.runningRequests.apiLoadProductVariantDetail"
                    :class="b('variant-detail-loader')"
          />
          <c-product-variant-detail v-else
                                    :mapped-product-variant="item"
                                    :exclude-features="variantFeaturesAlreadyShownInTable"
          />
        </template>
      </e-table>
    </div>

    <e-button v-if="hasMoreVariants"
              :class="b('load-more-variants-button')"
              :progress="productDetailStore.runningRequests.apiLoadFilteredProductVariants"
              @click="loadMoreVariants"
    >
      {{ $t('l-product-detail.moreVariantsButtonLabel') }}
    </e-button>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { AxiosPromise } from 'axios';
  import useProductDetailStore, { ProductScopeIdentifier } from '@/stores/product-detail';
  import cProductVariantDetail, {
    MappedProductFeature,
    MappedProductVariant,
  } from '@/components/c-product-variant-detail.vue';
  import cStockIcon from '@/components/c-stock-icon.vue';
  import eIcon from '@/elements/e-icon.vue';
  import eLoader from '@/elements/e-loader.vue';
  import cAddToCart from '@/components/c-add-to-cart.vue';
  import cProductStock from '@/components/c-product-stock.vue';
  import eTable, { TableColumn, ToggleEvent } from '@/elements/e-table.vue';
  import eButton from '@/elements/e-button.vue';
  import cCollapse from '@/components/c-collapse.vue';
  import cFilter from '@/components/c-filter.vue';
  import {
    FILTER_SECTIONS, HybrisType, ProductTag, WINDOW_HISTORY_TYPES,
  } from '@/setup/globals';
  import { ListName } from '@/plugins/google-tag-manager';
  import useSessionStore from '@/stores/session';
  import getFeaturesByProduct from '@/helpers/get-features-by-product';
  import mapFeatureValues from '@/helpers/map-feature-values';
  import mapStockIndicator from '@/helpers/map-stock-indicator';
  import useCustomerPriceStore from '@/stores/customer-price';
  import { Facet } from '@/types/facet';
  import { Product, VariantsResult } from '@/types/product';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import getBasePriceUnit from '@/helpers/get-base-price-unit';

  type Setup = {
    productDetailStore: ReturnType<typeof useProductDetailStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
    customerPriceStore: ReturnType<typeof useCustomerPriceStore>;
    ListName: typeof ListName;
    addContextPathToUrl: typeof addContextPathToUrl;
  }
  type Data = {
    show: boolean;
    currentVariantPage: number;
    initialProduct?: Product;
  }

  /**
   * Renders a product variant info for the product variant table.
   *
   * **WARNING: uses 'v-html' for feature values. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-product-variants-table',
    components: {
      cFilter,
      cCollapse,
      eButton,
      eTable,
      cProductStock,
      cAddToCart,
      eLoader,
      eIcon,
      cStockIcon,
      cProductVariantDetail,
    },

    props: {
      /**
       * Gets the main product to render the variants of this product.
       */
      scope: {
        type: String as PropType<ProductScopeIdentifier>,
        required: true,
      },
    },
    emits: {
      changed: (product: Product) => !!product,
    },

    setup(): Setup {
      return {
        productDetailStore: useProductDetailStore(),
        sessionStore: useSessionStore(),
        customerPriceStore: useCustomerPriceStore(),
        ListName,
        addContextPathToUrl,
      };
    },
    data(): Data {
      return {
        currentVariantPage: 0,
        show: true,
        initialProduct: undefined,
      };
    },

    computed: {
      hasSpecialPriceOrLiquidationVariants(): boolean {
        return !!this.variantsResult?.results?.some(product => product.inLiquidation || product.hasSpecialPrice);
      },

      variantsResult(): VariantsResult | undefined {
        return this.productDetailStore.getVariantResult(this.scope);
      },

      primaryVariantFilters(): Facet[] {
        return this.variantsResult?.facets
          .filter(facet => facet.filterSection !== FILTER_SECTIONS.section1) || [];
      },

      section1VariantFilters(): Facet[] {
        return this.variantsResult?.facets
          .filter(facet => facet.filterSection === FILTER_SECTIONS.section1) || [];
      },

      filteredProductVariants(): Product[] {
        return this.variantsResult?.results || [];
      },

      productVariants(): MappedProductVariant[] {
        const productVariants = this.filteredProductVariants;

        return productVariants.map((product): MappedProductVariant => {
          const productFeatures = product.classifications
            ? getFeaturesByProduct(product, true, false)
            : [];
          const mappedProductFeatures: MappedProductFeature = {};

          productFeatures.forEach((feature) => {
            mappedProductFeatures[feature.name] = mapFeatureValues(feature);
          });

          return {
            id: product.code,
            features: mappedProductFeatures,
            isPromoted: product.tags?.includes(ProductTag.Promoted) || false,
            product,
            stockState: mapStockIndicator(product.stock),
            selected: product.code === this.initialProduct?.code, // Opens variant of initial product
            priceUnit: getBasePriceUnit(product.customerPrice || product.specialPrice || product.price) || '',
          };
        });
      },

      variantTableColumns(): TableColumn[] {
        const additionalColumns: TableColumn[] = [];
        const tableColumns: TableColumn[] = [{
          title: this.$t('global.articleCodeAbbreviated'),
          key: 'code',
          slotName: 'productCode',
          onClick(item, column, toggleDetailRow): void {
            toggleDetailRow();
          },
          sortable: false,
        }];
        const { getShowOnlyRecommendedRetailPrice } = this.sessionStore;

        let maxAdditionalColumns;

        if (this.$viewport.isXl) {
          maxAdditionalColumns = 5;
        } else if (this.$viewport.isLg) {
          maxAdditionalColumns = 4;
        } else if (this.$viewport.isMd) {
          maxAdditionalColumns = 3;
        } else if (this.$viewport.isSm) {
          maxAdditionalColumns = 2;
        } else {
          maxAdditionalColumns = 6;
        }

        if (this.productDetailStore.showPrices) {
          if (getShowOnlyRecommendedRetailPrice) {
            additionalColumns.push({
              title: `${this.$t('global.price.recommendedRetailPriceShort')} ${this.sessionStore.getCurrencyIsoCode}`,
              key: 'recommendedRetailPrice',
              slotName: 'price',
              align: 'right',
              sortable: false,
            });
          } else {
            additionalColumns.push({
              title: `${this.$t('global.price.gross')} ${this.sessionStore.getCurrencyIsoCode}`,
              key: 'grossAndSpecialPrice',
              slotName: 'grossAndSpecialPrice',
              align: 'right',
              sortable: false,
            });
          }

          if (this.$viewport.isSm && this.hasSpecialPriceOrLiquidationVariants) {
            additionalColumns.push({
              title: this.$t('l-product-detail.tableColumnTitleLiquidationOrDiscount'),
              titleHidden: true,
              sortable: false,
              key: 'inLiquidation',
              slotName: 'liquidationOrDiscount',
            });
          }

          if (!getShowOnlyRecommendedRetailPrice && this.sessionStore.flags.showCustomerSpecificPrices) {
            additionalColumns.push({
              title: `${this.$t('global.price.net')} ${this.sessionStore.getCurrencyIsoCode}`,
              key: 'price',
              slotName: 'price',
              align: 'right',
              sortable: false,
            });
          }

          additionalColumns.push({
            title: this.$t('global.priceUnitLabelShort'),
            key: 'priceUnit',
            slotName: 'priceUnit',
            align: 'left',
            printOnly: true,
            sortable: false,
          });
        }

        additionalColumns.push({
          title: this.$t('global.actions'),
          titleHidden: true,
          key: 'actions',
          slotName: 'actions',
          sortable: false,
        });

        const additionalColumnTitles = [...new Set(this.productVariants?.flatMap(this.getFeatureNamesFromMappedProductVariant))];
        const featureColumns = additionalColumnTitles
          .slice(0, maxAdditionalColumns > 0 ? maxAdditionalColumns : 0)
          .map((title): TableColumn => ({
            title,
            key: title,
            slotName: 'featureValue',
            sortable: false,
          }));

        return tableColumns.concat(featureColumns, additionalColumns);
      },

      variantFeaturesAlreadyShownInTable(): string[] {
        return this.variantTableColumns.map(column => column.key);
      },

      hasMoreVariants(): boolean {
        return (this.variantsResult?.pagination.numberOfPages || 1)
          > this.currentVariantPage + 1;
      },

      hasActiveFilters(): boolean {
        const { value: queryValue } = this.variantsResult?.currentQuery.query || {};

        return !!(queryValue && queryValue.replace(':relevance', '').length >= 1);
      },
    },
    watch: {
      'sessionStore.getShowOnlyRecommendedRetailPrice': {
        /**
         * Re-renders the table if the recommended retail prices get toggled.
         * This was only necessary for fixing a JS error only occurring on productive systems.
         */
        handler() {
          this.reRender();
        },
      },
    },

    // beforeCreate() {},
    created() {
      this.initialProduct = this.productDetailStore.getProduct(this.scope);
    },
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Forces a re-rendering of the table.
       */
      reRender(): void {
        this.show = false;

        this.$nextTick(() => {
          this.show = true;
        });
      },

      isProduct(data: unknown): data is Product {
        return !!(data && typeof data === 'object' && 'type' in data && data?.type === HybrisType.Product);
      },

      onVariantFilterUpdate(filterUrl: string): void {
        this.currentVariantPage = 0;

        this.loadFilteredOrPaginatedVariants(filterUrl, true);
      },

      loadFilteredOrPaginatedVariants(apiUrl: string, updateHistory = false): void {
        const url = new URL(addContextPathToUrl(apiUrl), window.location.origin);

        if (this.currentVariantPage) {
          url.searchParams.append('page', `${this.currentVariantPage}`);
        }

        this.productDetailStore.apiLoadFilteredProductVariants(url.toString(), this.scope).then((response) => {
          const {
            results,
            currentQuery,
          } = response.data?.data?.variantsResult || {};

          if (updateHistory && currentQuery.url) {
            this.pushHistoryState(currentQuery.url);
          }

          if (Array.isArray(results)) {
            this.customerPriceStore.apiFetchCustomerPrices(results.map(product => ({
              code: product.code,
            })));
            this.$gtm.pushViewItemList(results.map(this.$gtm.mapProductToListItem), ListName.VariantList);
          }
        });
      },

      pushHistoryState(filterUrl: string): void {
        const url = new URL(addContextPathToUrl(filterUrl), window.location.origin);

        if (window.location.href !== url.href) {
          window.history.pushState({
            type: WINDOW_HISTORY_TYPES.productDetailVariantFilterUpdate,
            url: url.href,
          }, '', url);
          this.$gtm.pushHistoryChange();
        }
      },

      getFeatureNamesFromMappedProductVariant(mappedVariant: MappedProductVariant): string[] {
        const { features } = mappedVariant;

        return features ? Object.keys(features) : [];
      },

      onDetailToggle(payload: ToggleEvent): void {
        const {
          open,
          item: {
            product,
          },
        } = payload;

        if (open && this.isProduct(product) && product.apiUrl && product.url) {
          this.$gtm.pushSelectItem(product, ListName.VariantList);

          this.loadVariantProduct(product.url, product.apiUrl).then((response) => {
            this.$emit('changed', response?.data?.data?.product);
          });
        }
      },

      loadVariantProduct(url: string, apiUrl: string): AxiosPromise {
        this.pushHistoryState(url);

        return this.productDetailStore.apiLoadProductVariantDetail(apiUrl, this.scope).then((response) => {
          const product = this.productDetailStore.getProduct(this.scope);

          if (product) {
            this.$gtm.pushViewItem(this.$gtm.mapProductToListItem(product));
          }

          return response;
        });
      },

      loadMoreVariants(): void {
        const { apiUrl } = this.variantsResult?.currentQuery || {};

        if (apiUrl) {
          this.currentVariantPage += 1;
          this.loadFilteredOrPaginatedVariants(apiUrl);
        }
      },

      onClickResetAll(): void {
        const { resetApiUrl } = this.variantsResult?.currentQuery || {};

        if (resetApiUrl) {
          this.onVariantFilterUpdate(resetApiUrl);
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-product-variants-table {
    $this: &;

    &__variant-product-code {
      display: flex;
      align-items: center;
      column-gap: variables.$spacing--10;
    }

    &__variant-code {
      display: inline-block;
      font-weight: variables.$font-weight--bold;

      .e-table__data-row--detail-visible & {
        color: variables.$color-primary--1;
      }

      &--customer-code {
        color: variables.$color-grayscale--400;
      }
    }

    &__variant-actions {
      display: flex;
      gap: variables.$spacing--10;
      justify-content: flex-end;
      margin-left: auto;

      @include mixins.media(sm) {
        flex-direction: column;
        align-items: flex-end;
      }

      @include mixins.media(lg) {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }

      .c-add-to-cart {
        min-width: 180px;

        @include mixins.media(lg) {
          min-width: 220px;
        }
      }
    }

    &__table-icon {
      @include mixins.media(sm) {
        margin: auto;
      }
    }

    &__variant-price--strike-through {
      text-decoration: line-through;
    }

    &__variant-filters {
      margin-bottom: variables.$spacing--30;

      @include mixins.media(md) {
        margin-bottom: variables.$spacing--60;
      }

      @include mixins.media($media: print) {
        display: none;
      }
    }

    &__filter-collapse {
      margin-bottom: variables.$spacing--10;

      @include mixins.media($down: md) {
        border-bottom: 4px solid variables.$color-grayscale--0;
      }
    }

    &__filter-head {
      padding: variables.$spacing--10 0;
    }

    &__filter-toggle {
      @include mixins.heading-h2(false);

      display: flex;
      gap: variables.$spacing--15;
      align-items: center;

      .e-icon {
        transition: transform variables.$transition-duration--300 ease-in-out;
      }

      .c-collapse--open & .e-icon {
        transform: rotate(180deg);
      }
    }

    &__load-more-variants-button {
      margin: variables.$spacing--30 auto 0;

      @include mixins.media($media: print) {
        display: none;
      }
    }

    &__section-title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-bottom: variables.$spacing--5;
      border-bottom: 4px solid variables.$color-grayscale--0;

      @include mixins.media($media: print) {
        font-size: variables.$font-size--20;
      }

      #{$this}__reset-all-button {
        @include mixins.media($down: sm) {
          display: none;
        }

        @include mixins.media($media: print) {
          display: none;
        }
      }
    }

    &__liquidation-icon,
    &__special-price-icon {
      align-self: flex-end;
      margin-right: variables.$spacing--5;
      transform: translateY(-15%); // Improves text alignment
      color: variables.$color-primary--1;

      @include mixins.media(md) {
        margin-right: 0;
        transform: none;
      }
    }

    &__variant-detail-loader {
      margin-block: variables.$spacing--20;
    }

    &__variant-promoted-flag {
      color: variables.$color-primary--1;
    }

    &__configuration-button {
      .e-button {
        @include mixins.font(variables.$font-size--16);

        padding-right: variables.$spacing--5;
        padding-left: variables.$spacing--5;
        white-space: nowrap;
      }
    }

    &__reset-all-button {
      @include mixins.font(variables.$font-size--16, null, variables.$font-weight--bold);

      display: flex;
      grid-column-gap: variables.$spacing--10;
      align-items: center;
      cursor: pointer;

      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }
    }

    &__reset-all-button--mobile {
      margin-bottom: variables.$spacing--20;

      @include mixins.media(md) {
        display: none;
      }
    }

    .c-filter + .c-filter {
      margin-top: variables.$spacing--20;
    }

    .e-table__header-cell--col-code {
      width: 0; // Forces min width for product code column.

      @include mixins.media($down: xs) {
        display: none;
      }
    }

    .e-table__header-row {
      @include mixins.media(sm) {
        position: sticky;
        top: var(--header-height);
        border-bottom: 0;
        background-color: variables.$color-grayscale--1000;
      }

      @include mixins.media(md) {
        top: calc(var(--header-height) + var(--sticky-product-header-height, 120px));
      }
    }

    .e-table__header-cell {
      padding-top: variables.$spacing--10;
      border-bottom: 2px solid variables.$color-grayscale--0;

      @include mixins.media(sm) {
        @include mixins.font(variables.$font-size--16);
      }
    }

    .e-table__sort-label {
      white-space: normal;
    }

    .e-table__data-cell--col-code::before {
      display: none; // Never show this columns label
    }

    .e-table__header-cell--detail-toggle,
    .e-table__data-cell--detail-toggle,
    .e-table__header-cell--col-actions,
    .e-table__data-cell--col-actions {
      @include mixins.media($media: print) {
        display: none;
      }
    }
  }
</style>
