<template>
  <transition :name="b('transition', { slideTop: $viewport.isMd })"
              @before-enter="onBeforeEnter"
              @after-enter="onAfterEnter"
              @before-leave="onBeforeLeave"
  >
    <div v-if="showSuggestions && searchSuggestionStore.result" :class="b()">
      <div v-outside-click="{ handler: onOutsideClick, excludeIds: ['c-product-search'] }"
           :class="b('inner')"
      >
        <div :class="b('products')">
          <h3>
            {{ $t('c-search-suggestions.titleProducts') }}
          </h3>
          <template v-if="searchSuggestionStore.getProducts.length">
            <ul :class="b('product-list')">
              <li v-for="product in mappedProducts"
                  :key="product.code"
                  :class="b('product-item')"
              >
                <div v-if="product.ferronormPackaging"
                     v-tooltip:top-start="$t('c-search-suggestions.flagFerronormPackaging')"
                     :class="b('flag', { ferronormPackaging: true })"
                >

                  <e-icon :alt="$t('c-search-suggestions.flagFerronormPackaging')"
                          icon="i-ferronorm-packaging"
                          size="25"
                  />
                </div>
                <a :href="addContextPathToUrl(product.url)"
                   :class="[b('product-link'), focusableElementClass]"
                   @click="onClickProduct(product)"
                >
                  <e-picture v-bind="product.picture"
                             :sizes="productPictureSizes"
                  />
                  <div :class="b('product-info')">
                    <h4 v-highlight-string="{ content: product.name, query: searchSuggestionStore.query, }"
                        :class="b('product-name')"
                    ></h4>
                    <div :class="b('product-code')">
                      <!-- eslint-disable vue/max-len -->
                      <span v-if="product.showAddToCart"
                            v-highlight-string="{ content: $t('c-search-suggestions.productCode', { productCode: product.code }), query: searchSuggestionStore.query, }"
                      ></span>
                      <!-- eslint-disable-line vue/max-len -->
                      <span v-else
                            v-highlight-string="{ content: product.orderNumber, query: searchSuggestionStore.query, }"
                      ></span>
                    </div>
                  </div>
                </a>
                <div :class="b('product-actions')">
                  <c-add-to-cart v-if="product.showAddToCart"
                                 :product="product"
                                 :show-quantity-select="false"
                                 :gtm-list-name="ListName.SearchSuggestions"
                  />
                  <e-button v-else
                            :href="product.url"
                            variant="secondary"
                            height="200"
                  >
                    <e-icon icon="i-variant-list" size="22" />
                  </e-button>
                </div>
              </li>
            </ul>
            <a v-if="searchSuggestionStore.result.productsAmount > searchSuggestionStore.getProducts.length"
               :href="searchResultPageUrl"
               :class="[b('search-result-page-link'), focusableElementClass]"
            >
              <span :class="b('search-result-page-link-inner')">
                {{ $t('c-search-suggestions.linkAllProducts', { amount: searchSuggestionStore.result.productsAmount }) }}
                <e-icon icon="i-arrow--right" size="15" />
              </span>
            </a>
          </template>
          <p v-else :class="b('no-product-results')">
            {{ $t('c-search-suggestions.textNoProductResults') }}
          </p>
        </div>
        <div v-if="searchSuggestionStore.result.categoriesAmount" :class="b('categories')">
          <h3>
            {{ $t('c-search-suggestions.titleCategories') }}
          </h3>
          <ul :class="b('category-group-list')">
            <li v-for="categoryGroup in mappedCategoryGroups"
                :key="categoryGroup.code"
                :class="b('category-group-item')"
            >
              <h4 v-if="mappedCategoryGroups.length > 1" :class="b('category-group-title')">
                {{ categoryGroup.name }}
              </h4>
              <ul :class="b('category-list')">
                <li v-for="category in categoryGroup.categories"
                    :key="category.code"
                    :class="b('category-item')"
                >
                  <a :href="addContextPathToUrl(category.url)"
                     :class="[b('category-link'), focusableElementClass]"
                  >
                    <e-icon icon="i-arrow--right" size="15" />
                    <span v-highlight-string="{ content: category.term, query: searchSuggestionStore.query, }"
                          :class="b('category-link-inner')"
                    ></span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <a v-if="searchSuggestionStore.result.categoriesAmount > searchSuggestionStore.getCategories.length"
             :href="searchResultPageUrl"
             :class="[b('search-result-page-link'), focusableElementClass]"
          >
            <span :class="b('search-result-page-link-inner')">
              {{ $t('c-search-suggestions.linkAllCategories', { amount: searchSuggestionStore.result.categoriesAmount }) }}
              <e-icon icon="i-arrow--right" size="15" />
            </span>
          </a>
        </div>
        <div v-if="searchSuggestionStore.result.contentsAmount" :class="b('contents')">
          <h3>
            {{ $t('c-search-suggestions.titleContents') }}
          </h3>
          <ul :class="b('content-list')">
            <li v-for="(content, index) in searchSuggestionStore.getCmsContents"
                :key="index"
                :class="b('content-item')"
            >
              <a :href="addContextPathToUrl(content.url)"
                 :class="[b('content-link'), focusableElementClass]"
              >
                <e-icon icon="i-arrow--right" size="15" />
                <span v-highlight-string="{ content: content.title, query: searchSuggestionStore.query, }"
                      :class="b('content-link-inner')"
                ></span>
              </a>
            </li>
          </ul>
          <a v-if="searchSuggestionStore.result.contentsAmount > searchSuggestionStore.getCmsContents.length"
             :href="searchResultPageUrl"
             :class="[b('search-result-page-link'), focusableElementClass]"
          >
            <span :class="b('search-result-page-link-inner')">
              {{ $t('c-search-suggestions.linkAllContents', { amount: searchSuggestionStore.result.contentsAmount }) }}
              <e-icon icon="i-arrow--right" size="15" />
            </span>
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
  import tooltipDirective from '@/plugins/tooltip/directives/directive';
  import useSearchSuggestionStore, { Result } from '@/stores/search-suggestion';
  import useSessionStore from '@/stores/session';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import ePicture from '@/elements/e-picture.vue';
  import eIcon from '@/elements/e-icon.vue';
  import eButton from '@/elements/e-button.vue';
  import cAddToCart from '@/components/c-add-to-cart.vue';
  import mapProductImagesSrcSet from '@/helpers/map-product-images-srcset';
  import searchResultPageUrl from '@/helpers/search-result-page-url';
  import groupCategoriesByThreshold, { CategoriesGrouped, CategoryGroup } from '@/helpers/group-categories-by-threshold';
  import { ListName } from '@/plugins/google-tag-manager';
  import { Product } from '@/types/product';
  import { ImageSrcset } from '@/types/image';
  import { ImageSizes } from '@/types/sizes';
  import { bodyScrollOptions } from '@/setup/options';

  const componentName = 'c-search-suggestions';
  const focusableElementClass = `${componentName}__focusable-element`;

  interface Setup {
    searchSuggestionStore: ReturnType<typeof useSearchSuggestionStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
    addContextPathToUrl: typeof addContextPathToUrl;
    productPictureSizes: ImageSizes;
    focusableElementClass: typeof focusableElementClass;
    ListName: typeof ListName;
  }

  interface Data {
    focusedItemIndex: number | null;
    focusableElements: HTMLElement[];
  }

  interface ProductSuggestion extends Product {
    picture: ImageSrcset;
  }

  /**
   * Renders the search suggestions overlay.
   */
  export default defineComponent({
    name: componentName,
    components: {
      cAddToCart,
      eButton,
      ePicture,
      eIcon,
    },

    directives: {
      tooltip: tooltipDirective,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        searchSuggestionStore: useSearchSuggestionStore(),
        sessionStore: useSessionStore(),
        addContextPathToUrl,
        productPictureSizes: {
          xxs: 60,
          fallback: 60,
        },
        focusableElementClass,
        ListName,
      };
    },
    data(): Data {
      return {
        focusedItemIndex: null,
        focusableElements: [],
      };
    },

    computed: {
      showSuggestions(): boolean {
        const { query, hasResults, minimumCharacters } = this.searchSuggestionStore;

        return query.length >= minimumCharacters && hasResults;
      },

      /**
       * Returns the URL for the search result page based on the current query.
       */
      searchResultPageUrl(): string {
        return searchResultPageUrl(this.searchSuggestionStore.query);
      },

      /**
       * Returns products enriched with mapped picture.
       */
      mappedProducts(): ProductSuggestion[] {
        return this.searchSuggestionStore.result?.products.map(product => ({
          ...product,
          picture: mapProductImagesSrcSet(product.images),
        })) || [];
      },

      /**
       * Returns grouped categories.
       */
      categoriesGrouped(): CategoriesGrouped | null {
        const { categories, groupingThreshold } = this.searchSuggestionStore.result || {};

        if (!Array.isArray(categories)) {
          return null;
        }

        return groupCategoriesByThreshold(
          categories,
          groupingThreshold || 3
        );
      },

      /**
       * Returns mapped category groups.
       */
      mappedCategoryGroups(): CategoryGroup[] {
        const { groups, remainingCategories } = this.categoriesGrouped || {};
        const mappedCategoryGroups = [];

        if (groups?.length) {
          mappedCategoryGroups.push(...groups.map(categoryGroup => ({
            ...categoryGroup,
            name: categoryGroup.name || this.$t('c-search-suggestions.defaultParentCategoryName'),
          })));
        }

        if (remainingCategories?.length) {
          mappedCategoryGroups.push({
            code: 'remaining-categories',
            name: this.$t('c-search-suggestions.titleRemainingCategories'),
            categories: remainingCategories,
          });
        }

        return mappedCategoryGroups;
      },
    },
    watch: {
      /**
       * Observes search result and resets focus.
       */
      'searchSuggestionStore.result': function(result: Result): void {
        this.focusedItemIndex = null;

        if (!result || !this.showSuggestions) {
          this.focusableElements = [];

          return;
        }

        this.$nextTick(() => {
          this.focusableElements = Array.from(this.$el?.querySelectorAll(`.${focusableElementClass}`));
        });
      },

      'searchSuggestionStore.hasResults': function(show) {
        this.sessionStore.disableHeaderShadow = show;
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      window.removeEventListener('keyup', this.onKeyUp);
    },
    // unmounted() {},

    methods: {
      /**
       * Clears search on outside click.
       */
      onOutsideClick(): void {
        this.searchSuggestionStore.clearSearch();
      },

      /**
       * Enables global backdrop and disables body scroll before suggestions are shown.
       */
      onBeforeEnter(element: Element): void {
        disableBodyScroll(element, bodyScrollOptions);
      },

      /**
       * Adds event listener when suggestions are shown.
       */
      onAfterEnter(): void {
        window.addEventListener('keyup', this.onKeyUp);
      },

      /**
       * Disables global backdrop.
       */
      onBeforeLeave(element: Element): void {
        window.removeEventListener('keyup', this.onKeyUp);
        enableBodyScroll(element);
      },

      /**
       * Handles Keyup Events.
       */
      onKeyUp(event: KeyboardEvent): void {
        switch (event.key) {
          case 'ArrowDown':
            this.focusItem('next');
            break;

          case 'ArrowUp':
            this.focusItem('previous');
            break;

          case 'Tab':
            this.onTabKeyUp(event);
            break;

          case 'Escape':
            this.searchSuggestionStore.clearSearch();
            break;

          // no default
        }
      },

      /**
       * Sets focus on the first result item if the user presses the 'Tab'-Key
       * and the focus not already lies inside the search suggestions.
       */
      onTabKeyUp(event: KeyboardEvent): void {
        const target = event.target as HTMLElement;
        const { focusableElements } = this;

        if (!focusableElements) {
          return;
        }

        if (!this.$el.contains(target)) {
          // Focus lies outside of search suggestion, focus first item.
          event.preventDefault();
          this.focusedItemIndex = 0;
          focusableElements[this.focusedItemIndex]?.focus();

          return;
        }

        const focusedItemIndex = focusableElements.indexOf(target);

        if (focusedItemIndex > -1) {
          this.focusedItemIndex = focusedItemIndex;
        }
      },

      /**
       * Sets focus to next or previous item.
       */
      focusItem(direction: 'next' | 'previous' = 'next'): void {
        const { focusableElements } = this;

        if (!focusableElements) {
          return;
        }

        let newFocus;

        if (this.focusedItemIndex === null) {
          // Focus first or last item depending on direction.
          newFocus = direction === 'next' ? 0 : focusableElements.length - 1;
        } else {
          // Focus next/previous item.
          newFocus = direction === 'next' ? this.focusedItemIndex + 1 : this.focusedItemIndex - 1;
        }

        if (focusableElements[newFocus]) {
          this.focusedItemIndex = newFocus;
          focusableElements[newFocus].focus();
        }
      },

      onClickProduct(product: Product): void {
        this.$gtm.pushSelectItem(product, ListName.SearchSuggestions);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-search-suggestions {
    $this: &;

    position: fixed;
    top: var(--header-height);
    left: 0;
    width: 100dvw;
    max-height: calc(100dvh - var(--header-height));
    overflow-y: auto;
    border-bottom: 4px solid variables.$color-grayscale--0;
    background-color: variables.$color-grayscale--1000;

    &__inner {
      @include mixins.layout();

      display: grid;
      grid-gap: variables.$spacing--30 variables.$spacing--50;
      grid-template-columns: 1fr;
      padding-top: variables.$spacing--20;
      padding-bottom: variables.$spacing--30;

      @include mixins.media(md) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
      }
    }

    &__products {
      position: relative;

      @include mixins.media(md) {
        grid-row: span 2;
      }

      &::after {
        @include mixins.media(md) {
          position: absolute;
          top: 0;
          right: - variables.$spacing--25;
          content: '';
          width: 2px;
          height: 100%;
          background-color: variables.$color-grayscale--0;
        }
      }
    }

    &__product-item {
      position: relative;
      display: grid;
      grid-column-gap: variables.$spacing--20;
      grid-template-columns: 1fr auto;
      margin-bottom: variables.$spacing--10;
      padding-bottom: variables.$spacing--10;
      border-bottom: 1px solid variables.$color-grayscale--0;
    }

    &__product-link {
      display: grid;
      grid-column-gap: variables.$spacing--20;
      grid-template-columns: 60px 1fr;

      &:focus,
      &:hover {
        #{$this}__product-name {
          border-bottom-color: variables.$color-primary--1;
        }
      }
    }

    &__product-name {
      @include mixins.font(variables.$font-size--18, variables.$line-height--20, variables.$font-weight--bold);
      @include mixins.hyphens();

      display: inline-block;
      margin-bottom: variables.$spacing--5;
      border-bottom: 2px solid transparent;
    }

    &__product-code {
      @include mixins.font(variables.$font-size--16);
    }

    &__product-actions {
      align-self: center;
    }

    &__flag {
      color: variables.$color-primary--1;
    }

    &__flag--ferronorm-packaging {
      position: absolute;
      top: - variables.$spacing--5;
      left: - variables.$spacing--5;
    }

    &__contents,
    &__categories {
      @include mixins.media(md) {
        grid-column: 2 / 3;
      }
    }

    &__category-group-list {
      border-bottom: 1px solid variables.$color-grayscale--0;
    }

    &__category-group-item {
      margin-bottom: variables.$spacing--10;
      padding-bottom: variables.$spacing--10;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__category-group-title {
      margin-bottom: variables.$spacing--15;
    }

    &__content-list {
      border-bottom: 1px solid variables.$color-grayscale--0;
    }

    &__content-link,
    &__category-link {
      @include mixins.font(variables.$font-size--18, variables.$line-height--20, variables.$font-weight--bold);

      display: inline-flex;
      margin-bottom: variables.$spacing--15;

      &:hover,
      &:focus {
        #{$this}__category-link-inner,
        #{$this}__content-link-inner {
          border-bottom-color: variables.$color-primary--1;
        }
      }

      .e-icon {
        margin-top: 0.15em;
        margin-right: variables.$spacing--5;
      }
    }

    &__category-link-inner,
    &__content-link-inner {
      border-bottom: 2px solid transparent;
    }

    &__search-result-page-link {
      @include mixins.font(variables.$font-size--18, variables.$line-height--20, variables.$font-weight--bold);

      display: flex;
      justify-content: flex-end;
      margin-top: variables.$spacing--15;

      &:hover,
      &:focus {
        #{$this}__search-result-page-link-inner {
          border-bottom-color: variables.$color-primary--1;
        }
      }
    }

    &__search-result-page-link-inner {
      @include mixins.font(variables.$font-size--18, variables.$line-height--20, variables.$font-weight--bold);

      display: inline-flex;
      align-items: center;
      border-bottom: 2px solid transparent;

      .e-icon {
        margin-left: variables.$spacing--5;
      }
    }

    &__transition--slide-top-enter-active,
    &__transition--slide-top-leave-active {
      @include mixins.z-index(back);

      transition: transform variables.$transition-duration--300 ease;
    }

    &__transition--slide-top-enter-from,
    &__transition--slide-top-leave-to {
      transform: translateY(-100%);
    }

    .v-highlight-string {
      color: variables.$color-primary--1;
    }
  }
</style>
