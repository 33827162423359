<template>
  <div :class="b(modifiers)">
    <slot name="head" :toggle="toggle" :is-open="isOpen">
      <button v-if="title"
              :class="b('toggle')"
              type="button"
              @click="toggle"
      >
        <span :class="b('icon')">
          <e-icon icon="i-arrow--down" size="22" />
        </span>
        <span :class="b('title')">
          {{ title }}
        </span>
      </button>
    </slot>
    <transition name="c-collapse--expand"
                @enter="onEnter"
                @after-enter="onAfterEnter"
                @before-leave="onBeforeLeave"
                @leave="onLeave"
    >
      <div v-if="isOpen" :class="b('content')">
        <div :class="b('inner')">
          <slot :is-open="isOpen"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  import { ComponentPublicInstance, defineComponent } from 'vue';
  import propScale from '@/helpers/prop.scale';
  import { cCollapseGroups, CollapseGroup } from '@/components/c-collapse-group.vue';
  import eIcon from '@/elements/e-icon.vue';
  import { Modifiers } from '@/plugins/vue-bem-cn/src/globals';

  // interface Setup {}

  interface Data {

    /**
     * Defines if the collapse is currently open.
     */
    isOpen: boolean;

    /**
     * Shows if the transition (open/close) is ongoing.
     */
    inTransition: boolean;

    /**
     * Holds the associated collapse group if the collapse is part of a group.
     */
    group: CollapseGroup | null;
  }

  /**
   * Displays collapsible content panels. Use `<c-collapse-group>` as a wrapper for multiple items.
   */
  export default defineComponent({
    name: 'c-collapse',
    components: { eIcon },
    // mixins: [],

    props: {
      /**
       * Sets the initial open state of the item.
       */
      open: {
        type: Boolean,
        default: false,
      },

      /**
       * Defines the padding of the inner content.
       */
      padding: propScale(500, [
        0,
        100,
        500,
        700,
      ]),

      /**
       * Title of the toggle item.
       */
      title: {
        type: String,
        default: null,
      },

      /**
       * Enables scroll position update, after the collapse opened, to make sure the toggle and content are visible.
       */
      keepInView: {
        type: Boolean,
        default: true,
      },
    },

    // setup(): Setup {},
    data(): Data {
      return {
        isOpen: this.open,
        inTransition: false,
        group: null,
      };
    },

    computed: {
      /**
       * Returns modifier classes.
       */
      modifiers(): Modifiers {
        return {
          open: this.isOpen,
          transition: this.inTransition,
          padding: this.padding,
        };
      },
    },

    watch: {
      /**
       * Closes the collapse when another element of the associated group was opened.
       */
      'group.openCollapse': function(openCollapse: ComponentPublicInstance | null) {
        if (this.isOpen && this.group?.oneOpen && openCollapse?.$el !== this.$el) {
          this.isOpen = false;
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.group = cCollapseGroups.find(group => group.component?.$el.contains(this.$el)) || null;
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeDestroy() {},
    // destroyed() {},

    methods: {
      /**
       * Toggles the collapse.
       */
      toggle(): void {
        this.isOpen = !this.isOpen;

        if (this.isOpen) {
          const { group } = this;

          if (group) {
            group.openCollapse = this;
          }
        }
      },

      /**
       * Handler for when collapse gets opened.
       */
      onEnter(el: Element): void {
        (el as HTMLDivElement).style.maxHeight = '0px';
        (el as HTMLDivElement).style.maxHeight = `${el.scrollHeight}px`;
        this.inTransition = true;
      },

      /**
       * Handler for when collapse finished opening.
       */
      onAfterEnter(el: Element): void {
        (el as HTMLDivElement).style.maxHeight = 'none';

        this.scrollIntoView();
        this.inTransition = false;
      },

      /**
       * Handler for when before collapse gets closed.
       */
      onBeforeLeave(el: Element): void {
        (el as HTMLDivElement).style.maxHeight = `${el.scrollHeight}px`;
        this.inTransition = true;
      },

      /**
       * Handler for when collapse gets close.
       */
      onLeave(el: Element): void {
        (el as HTMLDivElement).style.maxHeight = '0px';
        this.inTransition = false;
      },

      /**
       * Keeps the top of the collapse inside the viewport, if content too high.
       */
      scrollIntoView(): void {
        if (!this.keepInView) {
          return;
        }

        const rect = this.$el.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const isOutsideStartOfViewport = rect.top < 0;
        const isAtStartOfViewport = rect.top < (viewportHeight / 4);
        const isOutsideEndOfViewport = rect.bottom > viewportHeight;

        if (isOutsideStartOfViewport || (!isAtStartOfViewport && isOutsideEndOfViewport)) {
          this.$el.scrollIntoView({ behavior: 'smooth' });
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '../setup/scss/variables';
  @use '../setup/scss/mixins';
  @use '../setup/scss/functions';
  // @use '../setup/scss/extends' as *;

  .c-collapse {
    $this: &;

    @include mixins.scroll-margin-top;

    &__toggle {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: variables.$spacing--10 0;
      background: none;
      cursor: pointer;
      color: variables.$color-grayscale--0;
      -webkit-tap-highlight-color: transparent;
    }

    &__icon {
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      align-items: center;
      margin-right: variables.$spacing--15;
      transition: all variables.$transition-duration--300 ease-in-out;
    }

    &__title {
      @include mixins.font(variables.$font-size--20, variables.$line-height--25, variables.$font-weight--bold);
    }

    &__content {
      @include mixins.font(variables.$font-size--16);

      max-height: none;
      overflow: hidden;
      opacity: 0;
      transition: max-height variables.$transition-duration--300 ease-in-out;
    }

    &__inner {
      #{$this}--padding-0 & {
        padding: 0;
      }

      #{$this}--padding-100 & {
        padding: variables.$spacing--5 0;
      }

      #{$this}--padding-500 & {
        padding: variables.$spacing--15 0;
      }

      #{$this}--padding-700 & {
        padding: variables.$spacing--15 0 variables.$spacing--25;
      }
    }

    &--open {
      #{$this}__icon {
        transform: rotate(180deg);
      }

      &:not(#{$this}--transition) #{$this}__content {
        overflow: visible;
      }
    }

    &--open &__content,
    .c-collapse--expand-enter-active,
    .c-collapse--expand-leave-active {
      opacity: 1;
    }
  }
</style>
