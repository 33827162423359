<template>
  <c-modal :is-open="!!product"
           size="950"
           close-on-outside-click
           @close="$emit('close')"
  >
    <e-loader v-if="productDetailStore.runningRequests.apiFetchProductDetail" />

    <div v-if="productDetail && productVariantResult" :class="b('wrapper')">
      <!-- Product Header -->
      <div :class="b('header')">
        <c-sticky-product-header :product="productDetail" />
      </div>

      <!-- Actions -->
      <div :class="b('actions')">
        <e-button
          :href="productDetail.url"
          height="200"
          variant="secondary"
        >
          {{ $t('c-product-variants-modal.goToProduct') }}
        </e-button>
      </div>

      <!-- Product Variants Table -->
      <c-product-variants-table :scope="ProductScopeIdentifier.VARIANTS_MODAL" />
    </div>
  </c-modal>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import useCustomerPriceStore from '@/stores/customer-price';
  import eLoader from '@/elements/e-loader.vue';
  import eButton from '@/elements/e-button.vue';
  import cModal from '@/components/c-modal.vue';
  import cStickyProductHeader from '@/components/c-sticky-product-header.vue';
  import cProductVariantsTable from '@/components/c-product-variants-table.vue';
  import useProductDetailStore, { ProductScopeIdentifier } from '@/stores/product-detail';
  import { Product, VariantsResult } from '@/types/product';

  interface Setup {
    customerPriceStore: ReturnType<typeof useCustomerPriceStore>;
    productDetailStore: ReturnType<typeof useProductDetailStore>;
    ProductScopeIdentifier: typeof ProductScopeIdentifier;
  }
  // interface Data {}

  /**
   * Renders the product variants modal.
   */
  export default defineComponent({
    name: 'c-product-variants-modal',

    components: {
      eButton,
      cProductVariantsTable,
      cStickyProductHeader,
      eLoader,
      cModal,
    },

    props: {
      /**
       * Expects a product object.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    emits: {
      close: () => true,
    },

    setup(): Setup {
      return {
        customerPriceStore: useCustomerPriceStore(),
        productDetailStore: useProductDetailStore(),
        ProductScopeIdentifier,
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      productDetail(): Product | undefined {
        return this.productDetailStore.getProduct(ProductScopeIdentifier.VARIANTS_MODAL);
      },

      productVariantResult(): VariantsResult | undefined {
        return this.productDetailStore.getVariantResult(ProductScopeIdentifier.VARIANTS_MODAL);
      },
    },
    watch: {
      /**
       * Observes the given product and fetches the product detail and variants result.
       */
      product: {
        handler(product: Product | undefined) {
          if (product?.code
            && this.productDetailStore.getProduct(ProductScopeIdentifier.VARIANTS_MODAL)?.code !== product.code
          ) {
            this.productDetailStore.scope[ProductScopeIdentifier.VARIANTS_MODAL] = {};
            this.productDetailStore.apiFetchProductDetail(product.code, ProductScopeIdentifier.VARIANTS_MODAL);
          }
        },
        immediate: true,
      },

      /**
       * Observes the computed property productVariantResult and fetches the product variants prices.
       */
      productVariantResult: {
        handler(productVariantResult: VariantsResult | undefined) {
          if (productVariantResult) {
            const variantCodes = productVariantResult?.results?.map(({ code }) => ({ code }));

            if (Array.isArray(variantCodes) && variantCodes.length) {
              this.customerPriceStore.apiFetchCustomerPrices(variantCodes);
            }
          }
        },
        immediate: true,
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-product-variants-modal {
    &__wrapper {
      display: grid;
      grid-row-gap: variables.$spacing--30;
    }

    &__header {
      .c-sticky-product-header {
        position: relative;
        inset: 0;
        box-shadow: none;
      }

      .c-sticky-product-header__wrapper,
      .c-sticky-product-header__inner {
        padding: 0;
      }
    }

    &__actions {
      display: flex;
    }
  }
</style>
