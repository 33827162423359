/* eslint-disable vue/max-len */
import { ImagePosition } from '@/components/c-marketing-paragraph.vue';
import { CmsColumnWidth } from '@/components/c-cms-row-container.vue';
import { ColumnWidth } from '@/components/c-row-container.vue';
import cmsLinkAttributes, { LinkWithModalAttribute } from '@/helpers/cms-link-attributes';
import mapMediaContainerSrcSet, { MediaFormatFilter } from '@/helpers/map-media-container-srcset';
import mapCmsColumnWidth from '@/helpers/map-column-width';
import { CmsImagePosition, CmsMarketingParagraph } from '@/types/cms-marketing-paragraph';
import { ImageSrcset } from '@/types/image';
import decodeBase64 from '@/helpers/decode-base64';

export interface MarketingParagraphProps {
    border: boolean;
    headline: string;
    headlineTag: string;
    headlineClasses?: string;
    mainImage?: ImageSrcset;
    imagePosition?: ImagePosition;
    link: LinkWithModalAttribute | undefined;
    text: string;
    width: ColumnWidth;
}

/**
 * Maps the given CMS data to a MarketingParagraphProps compatible object.
 */
export default function mapCmsMarketingParagraphProps(marketingParagraph: CmsMarketingParagraph): MarketingParagraphProps {
    const mainImage = Array.isArray(marketingParagraph.mainImage?.medias) && marketingParagraph.mainImage?.medias.length
    ? mapMediaContainerSrcSet(marketingParagraph.mainImage, MediaFormatFilter.MarketingParagraph)
    : undefined;

    const mappedMarketingParagraph: MarketingParagraphProps = {
        border: marketingParagraph.border,
        headline: marketingParagraph.headline,
        headlineTag: marketingParagraph.headlineTag,
        headlineClasses: marketingParagraph.headlineClasses,
        link: cmsLinkAttributes(marketingParagraph.link),
        text: decodeBase64(marketingParagraph.text ?? ''),
        mainImage,
        width: mapCmsColumnWidth(marketingParagraph.width),
        imagePosition: undefined,
    };

    if (mainImage && marketingParagraph.width === CmsColumnWidth.Col12) {
      mappedMarketingParagraph.imagePosition = marketingParagraph.imagePosition === CmsImagePosition.Right ? ImagePosition.Right : ImagePosition.Left;
    }

   return mappedMarketingParagraph;
}

