<template>
  <div :class="b()">
    <section v-if="productResultStore.getTopCmsComponents?.length"
             :class="b('cms-components', { top: true })"
    >
      <c-cms-components-wrapper :components="productResultStore.getTopCmsComponents" />
    </section>
    <c-category-links />
    <div v-if="hasValidFacets" :class="b('filter-wrapper')">
      <c-collapse v-if="facets.length"
                  :class="b('filter-collapse')"
                  :open="$viewport.isMd"
                  :padding="700"
      >
        <template #head="{ toggle, isOpen }">
          <div :class="b('filter-head')">
            <div :class="b('filter-toggle', { isOpen })"
                 role="button"
                 @click="toggle"
            >
              <span :class="b('filter-toggle-icon')">
                <e-icon icon="i-arrow--down" size="25" />
              </span>
              <h2 :class="b('filter-title')">
                {{ $t('global.filters') }}
              </h2>
            </div>
            <button v-if="hasFilterSet"
                    :class="b('filter-reset')"
                    :disabled="isLoading"
                    type="button"
                    @click="resetFilter"
            >
              <span :class="b('filter-reset-label')">
                {{ $t('c-product-result.buttonResetFilter') }}
              </span>
              <e-icon icon="i-close" size="15" />
            </button>
          </div>
        </template>
        <c-filter :facets="facets"
                  :loading="isLoading"
                  @update="onUpdateFilter"
        />
      </c-collapse>
      <c-collapse v-if="!$viewport.isMd && availabilityFacets.length"
                  :class="b('filter-collapse')"
                  :padding="700"
      >
        <template #head="{ toggle, isOpen }">
          <div :class="b('filter-head')">
            <div :class="b('filter-toggle', { isOpen })"
                 role="button"
                 @click="toggle"
            >
              <span :class="b('filter-toggle-icon')">
                <e-icon icon="i-arrow--down" size="25" />
              </span>
              <h2 :class="b('filter-title')">
                {{ $t('global.availability') }}
              </h2>
            </div>
          </div>
        </template>
        <c-filter :facets="availabilityFacets"
                  :loading="isLoading"
                  @update="onUpdateFilter"
        />
      </c-collapse>
    </div>
    <template v-if="productResultStore.results.length">
      <c-product-grid :products="productResultStore.results"
                      :amount="productResultStore.getPagination?.totalNumberOfResults"
                      :gtm-list-name="gtmListName"
      >
        <li v-for="placeholder in placeholders"
            :key="placeholder.index"
            class="c-product-grid__list-item--placeholder"
        >
          <c-product-placeholder :placeholder="placeholder"
                                 @enter-viewport="onPlaceholderEnterViewport"
          />
        </li>
      </c-product-grid>
      <div ref="endOfListContainer" :class="b('end-of-list')">
        <e-button v-if="!isEndOfListReached"
                  :progress="isLoading"
                  @click="requestPage({ page: productResultStore.getCurrentPage + 1 })"
        >
          {{ $t('c-product-result.buttonLoadMore') }}
        </e-button>
      </div>
    </template>
    <section v-if="productResultStore.getBottomCmsComponents?.length"
             :class="b('cms-components', { bottom: true })"
    >
      <c-cms-components-wrapper :components="productResultStore.getBottomCmsComponents" />
    </section>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    PropType,
    Ref,
    ref,
  } from 'vue';
  import { AxiosPromise } from 'axios';
  import useProductResultStore, { ApiGetProductsPayload } from '@/stores/product-result';
  import useSessionStore from '@/stores/session';
  import eButton from '@/elements/e-button.vue';
  import eIcon from '@/elements/e-icon.vue';
  import cProductGrid from '@/components/c-product-grid.vue';
  import cProductPlaceholder, { ProductPlaceholder } from '@/components/c-product-placeholder.vue';
  import cFilter from '@/components/c-filter.vue';
  import cCollapse from '@/components/c-collapse.vue';
  import cCategoryLinks from '@/components/c-category-links.vue';
  import cCmsComponentsWrapper from '@/components/c-cms-components-wrapper.vue';
  import { Facet } from '@/types/facet';
  import { ListName } from '@/plugins/google-tag-manager';
  import { Product } from '@/types/product';
  import filterValidFacets from '@/helpers/filter-valid-facets';

  const numberOfInfiniteScrollLoads = 2;

  interface Setup {
    endOfListContainer: Ref<HTMLDivElement | undefined>;
    productResultStore: ReturnType<typeof useProductResultStore>;
    sessionStore: ReturnType<typeof useSessionStore>;
  }

  interface Data {
    infiniteScrollObserver: IntersectionObserver | undefined;
    placeholders: ProductPlaceholder[];
    placeholderPagesRequested: number[];
    isLoading: boolean;
  }

  /**
   * Renders a product result.
   */
  export default defineComponent({
    name: 'c-product-result',

    components: {
      cCmsComponentsWrapper,
      eIcon,
      eButton,
      cCollapse,
      cProductPlaceholder,
      cProductGrid,
      cFilter,
      cCategoryLinks,
    },

    props: {
      /**
       * Expects a GTM list name to be passed.
       */
      gtmListName: {
        type: String as PropType<ListName>,
        required: true,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        endOfListContainer: ref(),
        productResultStore: useProductResultStore(),
        sessionStore: useSessionStore(),
      };
    },
    data(): Data {
      return {
        infiniteScrollObserver: undefined,
        placeholders: [],
        placeholderPagesRequested: [],
        isLoading: false,
      };
    },

    computed: {
      hasValidFacets(): boolean {
        const { getFacets = [] } = this.productResultStore;

        return filterValidFacets(getFacets).length > 0;
      },

      /**
       * Returns whether the end of the list is reached.
       */
      isEndOfListReached(): boolean {
        const { getPagination } = this.productResultStore;

        if (!getPagination) {
          return true;
        }

        const { currentPage, numberOfPages } = getPagination;

        return currentPage + 1 >= numberOfPages;
      },

      /**
       * Returns if any filters are set.
       */
      hasFilterSet(): boolean {
        const { value } = this.productResultStore.getSearchResult?.currentQuery.query || {};

        return !!value && value !== ':relevance' && value !== '&#x3a;relevance';
      },

      /**
       * Returns main filter facets.
       * On mobile the facets from `SECTION_1` (availability facets) are filtered because they are displayed
       * in a separate collapse.
       */
      facets(): Facet[] {
        const { getFacets } = this.productResultStore;

        return this.$viewport.isMd
          ? getFacets
          : getFacets.filter(facet => facet.filterSection !== 'SECTION_1');
      },

      /**
       * Returns the availability facets.
       */
      availabilityFacets(): Facet[] {
        return this.productResultStore.getFacets.filter(facet => facet.filterSection === 'SECTION_1');
      },
    },
    watch: {
      /**
       * Disables infinite scroll after current page reaches threshold.
       */
      'productResultStore.getCurrentPage': function(currentPage) {
        if (currentPage + 1 > numberOfInfiniteScrollLoads) {
          this.infiniteScrollObserver?.disconnect();
          this.infiniteScrollObserver = undefined;
        }
      },
    },

    // beforeCreate() {},
    created() {
      const { getPagination, results } = this.productResultStore;

      if (getPagination) {
        const { currentPage, pageSize } = getPagination;

        if (currentPage > 0 && pageSize > 0) {
          this.placeholders = new Array(currentPage * pageSize).fill(null).map((item, index) => ({
            page: Math.floor(index / pageSize),
            index,
          }));
        }
      }

      this.gtmPushViewItemList(results);
    },
    // beforeMount() {},
    mounted() {
      const { endOfListContainer } = this;

      if (endOfListContainer && !(this.productResultStore.getCurrentPage + 1 > numberOfInfiniteScrollLoads)) {
        this.infiniteScrollObserver = new IntersectionObserver(this.infiniteScrollObserverCallback, {
          threshold: 0.25,
        });
        this.infiniteScrollObserver.observe(endOfListContainer);
      }
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      this.infiniteScrollObserver?.disconnect();
    },
    // unmounted() {},

    methods: {
      /**
       * Callback for infinite scroll intersection observer.
       */
      infiniteScrollObserverCallback([entry]: IntersectionObserverEntry[]): void {
        const { getCurrentPage, results } = this.productResultStore;

        if (!entry.isIntersecting || this.isEndOfListReached || !results.length) {
          return;
        }

        this.requestPage({ page: getCurrentPage + 1 });
      },

      /**
       * Requests a page from API.
       */
      requestPage(payload: Partial<ApiGetProductsPayload>): null | AxiosPromise {
        const { apiUrl } = this.productResultStore.getSearchResult?.currentQuery || {};

        if (!apiUrl) {
          return null;
        }

        this.infiniteScrollObserver?.unobserve(this.endOfListContainer as HTMLDivElement);
        this.isLoading = true;

        return this.productResultStore.apiGetProducts({
          apiUrl: payload.apiUrl || apiUrl,
          page: payload.page || 0,
          loadingPlaceholder: payload.loadingPlaceholder || false,
          reset: payload.reset || false,
        }).then((response) => {
          if (!payload.loadingPlaceholder) {
            const { searchResult } = response.data?.data || {};
            const { currentPage } = searchResult?.pagination || {};
            const url = new URL(`${window.location.origin}${this.sessionStore.contextPath}${searchResult.currentQuery.url}`);

            if (currentPage) {
              url.searchParams.set('page', `${currentPage}`);
            } else {
              url.searchParams.delete('page');
            }

            if (Array.isArray(searchResult.results)) {
              this.gtmPushViewItemList(searchResult.results);
            }

            if (import.meta.env.DEV) {
              return response; // Disables history manipulation for local development.
            }

            window.history.pushState(
              {},
              '',
              url.toString()
            );
            this.$gtm.pushHistoryChange();
          }

          return response;
        }).finally(() => {
          this.isLoading = false;

          this.$nextTick(() => {
            this.infiniteScrollObserver?.observe(this.endOfListContainer as HTMLDivElement);
          });
        });
      },

      /**
       * Handles placeholder entering the viewport.
       */
      onPlaceholderEnterViewport(placeholder: ProductPlaceholder): void {
        const { page } = placeholder;

        if (!this.placeholderPagesRequested.includes(page)) {
          this.placeholderPagesRequested.push(page);

          this.requestPage({ page, loadingPlaceholder: true })?.then(() => {
            this.placeholders = this.placeholders.filter(placeholderItem => placeholderItem.page !== page);
          });
        }
      },

      /**
       * Handles update of a filter value.
       */
      onUpdateFilter(apiUrl: string): void {
        this.requestPage({ page: 0, apiUrl, reset: true });
      },

      /**
       * Resets all filters.
       */
      resetFilter(): void {
        const { currentQuery } = this.productResultStore.getSearchResult || {};

        if (!currentQuery) {
          return;
        }

        this.requestPage({ page: 0, apiUrl: currentQuery.resetApiUrl, reset: true });
      },

      gtmPushViewItemList(products: Product[]): void {
        if (!products.length) {
          return;
        }

        this.$gtm.pushViewItemList(products.map(this.$gtm.mapProductToListItem), this.gtmListName);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-product-result {
    $this: &;

    &__end-of-list {
      display: flex;
      justify-content: center;
    }

    &__filter-collapse {
      margin-bottom: variables.$spacing--10;

      @include mixins.media($down: md) {
        border-bottom: 2px solid variables.$color-grayscale--0;
      }

      &:last-of-type {
        margin-bottom: -4px;
      }
    }

    &__filter-head {
      display: flex;
      justify-content: space-between;
      padding: variables.$spacing--10 0;

      @include mixins.media(md) {
        border-bottom: 4px solid variables.$color-grayscale--0;
      }
    }

    &__filter-toggle {
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;
      cursor: pointer;
      color: variables.$color-grayscale--0;
      -webkit-tap-highlight-color: transparent;
    }

    &__filter-toggle--is-open {
      #{$this}__filter-toggle-icon {
        transform: rotate(180deg);
      }
    }

    &__filter-reset {
      @include mixins.font(variables.$font-size--16, null, variables.$font-weight--bold);

      display: flex;
      align-items: center;
      cursor: pointer;

      @include mixins.media(md) {
        @include mixins.font(variables.$font-size--18);
      }

      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }

      &:disabled {
        cursor: default;
        color: variables.$color-grayscale--400;
      }
    }

    &__filter-reset-label {
      margin-right: variables.$spacing--10;
    }

    &__filter-toggle-icon {
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      align-items: center;
      margin-right: variables.$spacing--15;
      transition: all variables.$transition-duration--300 ease-in-out;

      @include mixins.media($down: sm) {
        width: 22px;
        height: 22px;
      }
    }

    &__filter-title {
      margin: 0;

      @include mixins.media($down: sm) {
        @include mixins.font(variables.$font-size--18);
      }
    }

    &__cms-components--top {
      margin-bottom: variables.$spacing--50;
    }

    &__cms-components--bottom {
      margin-top: variables.$spacing--50;
    }

    .c-product-grid {
      margin-bottom: variables.$spacing--50;
    }

    .c-category-links {
      margin-bottom: variables.$spacing--10;

      @include mixins.media(sm) {
        margin-bottom: variables.$spacing--40;
      }
    }
  }
</style>
