<template>
  <section :class="b()">
    <div :class="b('wrapper')">
      <div :class="b('inner')">
        <e-picture v-if="mainImage"
                   v-bind="mainImage"
                   :class="b('image')"
                   :width="150"
                   :height="150"
        />
        <div :class="b('details')">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="product.name" :class="b('title')"></div>

          <div :class="b('product-code')">
            <template v-if="!isBaseProduct">
              {{ $t('c-sticky-product-header.productCode') }}:
              <strong>{{ product.code }}</strong>
            </template>
            <span v-if="product.customerProductId" :class="b('customer-product-id')">
              {{ product.customerProductId }}
            </span>
          </div>

          <ul v-if="product.orderNumber || product.supplierCode"
              :class="b('additional-codes')"
          >
            <li v-if="product.orderNumber"
                :class="b('additional-code', { orderNumber: true })"
            >
              {{ product.orderNumber }}
            </li>
            <li v-if="product.supplierCode"
                :class="b('additional-code')"
            >
              {{ product.supplierCode }}
            </li>
          </ul>
        </div>
        <div :class="b('cart')">
          <template v-if="!isBaseProduct">
            <div :class="b('price')">
              <c-product-price-detailed :product="product" show-discount-group />
            </div>
            <div :class="b('actions')">
              <c-product-stock :product="product" variant="icons-only" />
              <c-add-to-cart :class="b('add-to-cart')"
                             :product="product"
                             show-product-actions
              />
            </div>
          </template>
        </div>

        <!-- Scroll to top -->
        <button
          :class="b('scroll-to-top-button')"
          :title="$t('global.expand')"
          type="button"
          @click="handleClickScrollToTop"
        >
          <e-icon icon="i-arrow--up" size="22" />
        </button>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import ePicture from '@/elements/e-picture.vue';
  import eIcon from '@/elements/e-icon.vue';
  import cAddToCart from '@/components/c-add-to-cart.vue';
  import cProductStock from '@/components/c-product-stock.vue';
  import cProductPriceDetailed from '@/components/c-product-price-detailed.vue';
  import mapMediaContainerSrcSet from '@/helpers/map-media-container-srcset';
  import { Product } from '@/types/product';
  import { ImageSrcset } from '@/types/image';

  // interface Setup {}

  // interface Data {}

  /**
   * Renders a sticky header for the product detail page.
   *
   * **WARNING: uses 'v-html' for `product.name`. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-sticky-product-header',

    components: {
      eIcon,
      cProductPriceDetailed,
      cProductStock,
      cAddToCart,
      ePicture,
    },

    props: {
      /**
       * Expects a product object.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    // emits: {},

    // setup(): Setup {},
    // data(): Data {
    //   return {};
    // },

    computed: {
      isBaseProduct(): boolean {
        return !this.product.baseProduct;
      },

      mainImage(): ImageSrcset | undefined {
        const galleryImage = this.product.galleryImages[0];

        return galleryImage ? mapMediaContainerSrcSet(galleryImage) : undefined;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.updateHeightVariable();
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      this.updateHeightVariable(0);
    },
    // unmounted() {},

    methods: {
      /**
       * Updates the height variable on the body.
       */
      updateHeightVariable(value?: number): void {
        document.body.style.setProperty(
          '--sticky-product-header-height',
          `${value || this.$el.getBoundingClientRect()?.height}px`
        );
      },

      handleClickScrollToTop(): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-sticky-product-header {
    $grid-area--title: title;
    $grid-area--image: image;
    $grid-area--cart: cart;
    $grid-area--details: details;

    @include mixins.z-index(dropdown);

    position: fixed;
    inset: var(--header-height, 0) 0 auto;
    box-shadow: 0 3px 3px -3px variables.$color-grayscale--400;

    &__wrapper {
      @include mixins.layout();
    }

    &__inner {
      position: relative;
      display: grid;
      grid-template-areas:
        '#{$grid-area--image} #{$grid-area--details} '
        '. #{$grid-area--cart}';
      grid-template-columns: 50px 1fr auto;
      gap: variables.$spacing--20;
      background: variables.$color-grayscale--1000;
      padding-block: variables.$spacing--10;

      @include mixins.media(md) {
        grid-template-areas: '#{$grid-area--image} #{$grid-area--details} #{$grid-area--cart}';
        grid-template-columns: 100px 1fr auto;
      }
    }

    &__title {
      @include mixins.heading-h3;

      grid-area: $grid-area--title;
      margin-bottom: 0;

      @include mixins.media(md) {
        margin-bottom: 0;
      }
    }

    &__image {
      grid-area: $grid-area--image;
      align-self: start;
    }

    &__details {
      grid-area: $grid-area--details;
    }

    &__additional-codes {
      display: flex;
      gap: variables.$spacing--10;
      align-items: center;
      margin-top: auto;
      padding-top: variables.$spacing--10;
    }

    &__additional-code {
      & + & {
        padding-left: variables.$spacing--10;
        border-left: 2px solid variables.$color-grayscale--0;
      }

      &--order-number {
        font-weight: variables.$font-weight--bold;
      }
    }

    &__cart {
      grid-area: $grid-area--cart;
      align-self: end;
    }

    &__price {
      margin-bottom: variables.$spacing--15;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: variables.$spacing--15;
    }

    &__customer-product-id {
      color: variables.$color-grayscale--0;

      &:not(:first-child) {
        margin-left: variables.$spacing--10;
        padding-left: variables.$spacing--10;
        border-left: 1px solid variables.$color-grayscale--0;
      }
    }

    &__scroll-to-top-button {
      position: absolute;
      bottom: variables.$spacing--5;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      color: variables.$color-grayscale--400;
    }
  }
</style>
