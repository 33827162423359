<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ title }}
    </h1>
    <!-- eslint-disable vue/no-v-html -->
    <p v-if="spellingCorrectionText"
       v-html="spellingCorrectionText"
       :class="b('spelling-correction-text')"
    ></p>
    <!-- eslint-enable vue/no-v-html -->
    <c-tabs v-if="tabs.length" :tabs="tabs" padding="800">
      <template #products-and-categories>
        <c-product-result :gtm-list-name="ListName.SearchResults" />
      </template>
      <template #contents>
        <ul :class="b('content-list')">
          <li v-for="(content, index) in productResultStore.getContents"
              :key="index"
              :class="b('content')"
          >
            <a :href="content.url" :class="b('content-link')">
              <h3>
                {{ content.title }}
              </h3>
              <p>
                {{ content.description }}
              </p>
              <div :class="b('content-link-indicator')">
                {{ $t('l-search-results.linkContentDetails') }}
                <e-icon icon="i-arrow--right" size="17" />
              </div>
            </a>
          </li>
        </ul>
      </template>
    </c-tabs>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { Layout } from '@/setup/globals';
  import productResultStore from '@/stores/product-result';
  import cProductResult from '@/components/c-product-result.vue';
  import cTabs, { Tab } from '@/components/c-tabs.vue';
  import eIcon from '@/elements/e-icon.vue';
  import setActiveLayout from '@/compositions/set-active-layout';
  import { ListName } from '@/plugins/google-tag-manager';

  const layout = Layout.SearchResult;

  interface Setup {
    productResultStore: ReturnType<typeof productResultStore>;
    ListName: typeof ListName;
  }
  // interface Data {}

  /**
   * Renders the search result page.
   *
   * **WARNING: uses 'v-html' for the 'spellingCorrectionText'. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: layout,
    components: {
      eIcon,
      cTabs,
      cProductResult,
    },

    // components: {},

    // props: {},
    // emits: {},

    setup(): Setup {
      setActiveLayout(layout);

      return {
        productResultStore: productResultStore(),
        ListName,
      };
    },
    // data(): Data {
    //   return {};
    // },

    computed: {
      title(): string {
        const { noResultsText } = this.productResultStore.getSearchResult || {};

        if (noResultsText) {
          return noResultsText;
        }

        return this.$tc('l-search-results.title', this.totalAmountOfResults, {
          count: this.totalAmountOfResults,
          query: this.query,
        });
      },

      spellingCorrectionText(): string | undefined {
        const { spellingCorrectionText } = this.productResultStore.getSearchResult || {};
        const { totalAmountOfResults } = this;

        if (spellingCorrectionText) {
          return this.$tc('l-search-results.spellCorrectionText', totalAmountOfResults, {
            count: totalAmountOfResults,
            text: spellingCorrectionText,
          });
        }

        return undefined;
      },

      /**
       * Returns the current search query.
       */
      query(): string {
        return this.productResultStore.getSearchResult?.freeTextSearch || '';
      },

      /**
       * Gets the total amount of search results.
       */
      totalAmountOfResults(): number {
        const { getPagination, getContentPagination } = this.productResultStore;

        return (getPagination?.totalNumberOfResults || 0) + (getContentPagination?.totalNumberOfResults || 0);
      },

      /**
       * Returns the tabs.
       * Restores active tab from URL hash if available.
       */
      tabs(): Tab[] {
        const { getPagination, getContentPagination } = this.productResultStore;
        const locationHash = window.location.hash.replace('#', '');

        const tabs: Tab[] = [];

        if (getPagination?.totalNumberOfResults) {
          const id = 'products-and-categories';

          tabs.push({
            title: this.$t('l-search-result.tabTitleProductsAndCategories', { amount: getPagination.totalNumberOfResults }),
            id,
            active: locationHash === id,
          });
        }

        if (getContentPagination?.totalNumberOfResults) {
          const id = 'contents';

          tabs.push({
            title: this.$t('l-search-result.tabTitleContents', { amount: getContentPagination?.totalNumberOfResults }),
            id,
            active: locationHash === id,
          });
        }

        if (tabs.length && !tabs.some(tab => tab.active)) {
          tabs[0].active = true;
        }

        return tabs;
      },
    },
    // watch: {},

    // beforeCreate() {},
    created() {
      const { query } = this;

      if (query) {
        this.$gtm.pushSearch(query);
      }
    },
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .l-search-result {
    &__spelling-correction-text,
    &__no-results-text {
      @include mixins.font(variables.$font-size--20, variables.$line-height--25, variables.$font-weight--bold);

      margin: variables.$spacing--40 0 variables.$spacing--40;
    }

    &__content-list {
      @include mixins.media(xl) {
        max-width: 75%;
      }
    }

    &__content-link {
      display: block;
      margin-bottom: variables.$spacing--70;
      border-bottom: 2px solid variables.$color-grayscale--0;

      &:hover,
      &:focus {
        border-color: variables.$color-primary--1;
      }
    }

    &__content-link-indicator {
      display: inline-flex;
      align-items: center;
      margin-bottom: variables.$spacing--5;

      .e-icon {
        margin-left: variables.$spacing--5;
      }
    }
  }
</style>
