<template>
  <div :class="b({ expanded, show })">
    <button
      :class="b('toggle')"
      type="button"
      @click="expanded = !expanded"
    >
      <e-icon icon="i-quick-add" size="30" />
    </button>
    <div :class="b('container')">
      <div :class="b('container-inner')">
        <c-quick-add-to-cart show-title />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import eIcon from '@/elements/e-icon.vue';
  import cQuickAddToCart from '@/components/c-quick-add-to-cart.vue';

  // type Setup = {}

  type Data = {
    show: boolean;
    expanded: boolean;
  }

  /**
   * Renders the global quick add to cart feature.
   */
  export default defineComponent({
    name: 'c-global-quick-add-to-cart',
    components: { cQuickAddToCart, eIcon },

    // props: {},
    // emits: {},

    // setup(): Setup {},
    data(): Data {
      return {
        show: false,
        expanded: false,
      };
    },

    // computed: {},
    watch: {
      expanded(expanded: boolean): void {
        if (expanded) {
          this.$el.querySelector('.c-quick-add-to-cart__article-number input')?.focus();
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      window.setTimeout(() => {
        this.show = true;
      }, 300);
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-global-quick-add-to-cart {
    $this: &;

    position: fixed;
    top: 300px;
    right: 0;
    display: flex;
    align-items: stretch;
    opacity: 0;
    background-color: variables.$color-grayscale--1000;
    box-shadow: 0 0 2px variables.$color-grayscale--400;
    transition: opacity variables.$transition-duration--300 ease-in-out;

    &__toggle {
      align-self: stretch;
      background-color: variables.$color-primary--1;
      cursor: pointer;
      color: variables.$color-grayscale--1000;
    }

    &__toggle,
    .c-quick-add-to-cart {
      padding: variables.$spacing--10 variables.$spacing--15;
    }

    &__container {
      display: grid;
      grid-template-columns: 0fr;
      align-items: center;
      transition: grid-template-columns variables.$transition-duration--200 ease-in-out;
    }

    &__container-inner {
      min-width: 0;
    }

    &--show {
      opacity: 1;
    }

    &--expanded &__container {
      grid-template-columns: 1fr;
    }

    .c-quick-add-to-cart {
      &__title {
        white-space: nowrap;
      }

      &__commission {
        width: 170px;
        min-width: initial;
      }
    }
  }
</style>
