<template>
  <div :class="b({ variant, isLastVisitedProduct })">
    <div :class="b('head')">
      <div :class="b('flags-and-badges')">
        <div v-if="product.tags?.includes(ProductTag.Promoted)"
             v-tooltip:top-start="$t('c-product-grid-item.flagPromoted')"
             :class="b('flag', { promoted: true })"
        >
          <e-icon :alt="$t('c-product-grid-item.flagPromoted')"
                  icon="i-rebuy"
                  size="25"
          />
        </div>
        <e-badge v-else-if="(product.newItem && product.numberOfVariants === 1) || product.allVariantsAreNew"
                 variant="hexagon"
        >
          {{ $t('c-product-grid-item.flagNewProduct') }}
        </e-badge>
        <div v-else-if="product.inLiquidation || (product.anyVariantIsInLiquidation && !product.showAddToCart)"
             v-tooltip:top-start="$t('c-product-grid-item.flagInLiquidation')"
             :class="b('flag', { discount: true })"
        >
          <e-icon :alt="$t('c-product-grid-item.flagInLiquidation')" icon="i-liquidation" size="25" />
        </div>
        <div v-else-if="product.hasSpecialPrice || product.anyVariantHasSpecialPrice"
             v-tooltip:top-start="$t('global.productStock.specialPrice')"
             :class="b('flag', { specialPrice: true })"
        >
          <e-icon :alt="$t('global.productStock.specialPrice')" icon="i-discount" size="25" />
        </div>
      </div>
      <div :class="b('price')">
        <c-product-price :product="product" />
      </div>
      <c-favourite-button :class="b('favourite-button')"
                          :product="product"
      />
    </div>
    <div :class="b('order-number')">
      {{ product.orderNumber }}
    </div>
    <a :class="b('picture')"
       :href="productUrl"
       @click="onClick"
    >
      <div v-if="product.ferronormPackaging"
           v-tooltip:top-start="$t('c-product-grid-item.flagFerronormPackaging')"
           :class="b('flag', { ferronormPackaging: true })"
      >

        <e-icon :alt="$t('c-product-grid-item.flagFerronormPackaging')"
                icon="i-ferronorm-packaging"
                size="25"
        />
      </div>
      <e-picture v-if="picture"
                 :srcset="picture.srcset"
                 :ratio="1"
                 :fallback="picture.fallback"
                 :alt="picture.alt"
                 :sizes="pictureSizes"
      />
    </a>
    <div :class="b('name')">
      <!-- eslint-disable vue/no-v-html -->
      <a v-html="product.name"
         :href="productUrl"
         :class="b('link')"
         @click="onClick"
      ></a>
      <!-- eslint-enable vue/no-v-html -->
    </div>
    <div :class="b('stock')">
      <c-product-stock v-if="product.stock"
                       :product="product"
                       :show-indicators="['substitute', 'stock', 'posStock']"
                       variant="icons-only"
      />
    </div>
    <div :class="b('actions')">
      <c-add-to-cart v-if="product.showAddToCart"
                     :product="product"
                     :gtm-list-name="gtmListName"
      />
      <e-button v-else-if="configurationLink"
                :href="configurationLink"
                height="200"
                variant="secondary"
                width="full"
      >
        {{ $t('c-product-grid-item.linkConfigure') }}
      </e-button>
      <e-button v-else-if="showDisplayVariantsButton"
                height="200"
                variant="secondary"
                width="full"
                type="button"
                @click="handleShowVariants"
      >
        {{ $t('c-product-grid-item.buttonShowVariants') }}
      </e-button>
      <e-button v-else
                :href="product.url"
                height="200"
                variant="secondary"
                width="full"
      >
        {{ $t('c-product-grid-item.buttonChooseVariant') }}
      </e-button>
    </div>
  </div>

  <!-- Variants Modal -->
  <c-product-variants-modal v-if="variantProduct" :product="variantProduct" @close="variantProduct = undefined" />
</template>

<script lang="ts">
  import {
    defineComponent,
    PropType,
  } from 'vue';
  import tooltipDirective from '@/plugins/tooltip/directives/directive';
  import { IS_STORAGE_AVAILABLE, StorageKey, ProductTag } from '@/setup/globals';
  import useSessionStore from '@/stores/session';
  import ePicture from '@/elements/e-picture.vue';
  import eButton from '@/elements/e-button.vue';
  import eIcon from '@/elements/e-icon.vue';
  import eBadge from '@/elements/e-badge.vue';
  import cProductPrice from '@/components/c-product-price.vue';
  import cAddToCart from '@/components/c-add-to-cart.vue';
  import cProductStock from '@/components/c-product-stock.vue';
  import cFavouriteButton from '@/components/c-favourite-button.vue';
  import cProductVariantsModal from '@/components/c-product-variants-modal.vue';
  import mapProductImagesSrcSet from '@/helpers/map-product-images-srcset';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import { ListName } from '@/plugins/google-tag-manager';
  import { ImageSrcset } from '@/types/image';
  import { Product } from '@/types/product';
  import { ImageSizes } from '@/types/sizes';

  interface Setup {
    ProductTag: typeof ProductTag;
    sessionStore: ReturnType<typeof useSessionStore>;
  }
  interface Data {
    variantProduct?: Product;
  }

  /**
   * Renders a product grid item.
   *
   * **WARNING: uses 'v-html' for the `product.name`. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-product-grid-item',

    components: {
      cProductVariantsModal,
      cFavouriteButton,
      cProductStock,
      eBadge,
      eButton,
      ePicture,
      eIcon,
      cAddToCart,
      cProductPrice,
    },

    directives: {
      tooltip: tooltipDirective,
    },

    props: {
      /**
       * Expects a product item.
       */
      product: {
        type: Object as PropType<Product>,
        required: true,
      },

      /**
       * Allows defining the variant of the item.
       */
      variant: {
        type: String,
        default: 'default',
        validator: (value: string) => [
          'default',
          'minified',
        ].includes(value),
      },

      /**
       * Expects a GTM list name to be passed.
       */
      gtmListName: {
        type: String as PropType<ListName>,
        required: true,
      },

      /**
       * Defines if a product variant should display the variant selection in a modal.
       */
      displayVariantsInModal: {
        type: Boolean,
        default: false,
      },
    },

    // emits: {},

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
        ProductTag,
      };
    },
    data(): Data {
      return {
        variantProduct: undefined,
      };
    },

    computed: {
      productUrl(): string {
        return addContextPathToUrl(this.product.url);
      },

      /**
       * Returns the product picture.
       */
      picture(): ImageSrcset | null {
        const { images } = this.product;

        if (!images) {
          return null;
        }

        return mapProductImagesSrcSet(images);
      },

      /**
       * Returns picture sizes depending on variant.
       */
      pictureSizes(): ImageSizes {
        if (this.variant === 'minified') {
          return {
            xxs: 85,
            fallback: 85,
          };
        }

        return {
          xxs: 439,
          xs: 353,
          sm: 306,
          md: 274,
          lg: 255,
          fallback: 210,
        };
      },

      /**
       * Returns the configuration link for products that are configurable and don't have multiple variants.
       */
      configurationLink(): string | undefined {
        const { configurationUrl, configurable, numberOfVariants } = this.product;

        return configurationUrl && configurable && numberOfVariants <= 1
          ? `${this.sessionStore.contextPath}${configurationUrl}`
          : undefined;
      },

      /**
       * Returns if the current product was the last visited one.
       */
      isLastVisitedProduct(): boolean {
        return IS_STORAGE_AVAILABLE
          ? window.localStorage.getItem(StorageKey.LastVisitedProduct) === `${this.product.code}`
          : false;
      },

      showDisplayVariantsButton() {
        return this.displayVariantsInModal
          && this.$viewport.isLg
          && !this.product.showAddToCart
          && !this.configurationLink;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      onClick(event: Event): void {
        if (this.showDisplayVariantsButton) {
          event.preventDefault();
          this.handleShowVariants();
        }

        this.$gtm.pushSelectItem(this.product, this.gtmListName);
        this.setLastVisitedProduct();
      },

      /**
       * Updates the last visited product in the storage.
       */
      setLastVisitedProduct(): void {
        if (IS_STORAGE_AVAILABLE) {
          window.localStorage.setItem(StorageKey.LastVisitedProduct, `${this.product.code}`);
        }
      },

      handleShowVariants(): void {
        this.variantProduct = this.product;
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-product-grid-item {
    $this: &;

    position: relative;
    display: grid;
    grid-template-areas: 'head' 'order-number' 'picture' 'name' 'stock' 'actions';
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto) 1fr repeat(2, auto);
    height: 100%;

    &__head {
      display: flex;
      grid-area: head;
    }

    &__name {
      @include mixins.font(variables.$font-size--18);

      grid-area: name;
      margin-bottom: variables.$spacing--10;
    }

    &__link {
      @include mixins.hyphens();
    }

    &__order-number {
      @include mixins.font(variables.$font-size--16);

      grid-area: order-number;
      min-height: 24px;
    }

    &__price {
      @include mixins.font(variables.$font-size--20, null, variables.$font-weight--bold);

      flex: 1;
    }

    &__flags-and-badges {
      display: flex;
      align-items: center;
      margin-right: variables.$spacing--10;

      &:empty {
        display: none;
      }
    }

    &__flag {
      color: variables.$color-primary--1;
    }

    &__flag--ferronorm-packaging {
      position: absolute;
      bottom: variables.$spacing--5;
      left: 0;
    }

    &__picture {
      position: relative;
      grid-area: picture;

      .e-picture {
        cursor: pointer;
      }
    }

    &__stock {
      display: flex;
      grid-area: stock;
      justify-content: flex-end;
      margin-bottom: variables.$spacing--10;

      .c-product-stock {
        @include mixins.font(variables.$font-size--16);
      }
    }

    &__actions {
      grid-area: actions;
    }

    &--variant-minified {
      grid-column-gap: variables.$spacing--10;
      grid-template-areas:
        'picture name name'
        'picture head head'
        'picture stock actions';
      grid-template-columns: 85px 1fr auto;
      grid-template-rows: auto;

      #{$this}__head {
        justify-self: flex-end;
        margin-bottom: variables.$spacing--10;
      }

      #{$this}__price {
        @include mixins.font(variables.$font-size--16);
      }

      #{$this}__stock {
        align-self: center;
        margin-bottom: 0;
      }

      #{$this}__name {
        @include mixins.font(variables.$font-size--16);

        margin-bottom: variables.$spacing--5;
      }

      #{$this}__flags-and-badges,
      #{$this}__flag,
      #{$this}__order-number,
      #{$this}__favourite-button {
        display: none;
      }
    }

    &--is-last-visited-product::before {
      position: absolute;
      right: 0;
      bottom: -12px;
      left: 0;
      display: block;
      content: '';
      height: 4px;
      background-color: variables.$color-primary--1;
      pointer-events: none;
    }
  }
</style>
