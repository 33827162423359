<template>
  <c-modal v-model:is-open="isOpen"
           :class="b()"
           :title="$t('c-substitute-products-modal.title')"
           size="800"
           close-on-outside-click
  >
    <e-loader v-if="productStockStore.runningRequests.apiFetchSubstituteProducts" />
    <ul v-else-if="results.length" :class="b('list')">
      <li v-for="product in results"
          :key="product.code"
          :class="b('item')"
      >
        <c-product-grid-item :product="product"
                             :gtm-list-name="ListName.SubstituteProducts"
        />
      </li>
    </ul>
    <div v-else :class="b('no-results-text')">
      {{ $t('global.noResultsText') }}
    </div>
  </c-modal>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import cModal from '@/components/c-modal.vue';
  import useProductStockStore from '@/stores/product-stock';
  import eLoader from '@/elements/e-loader.vue';
  import cProductGridItem from '@/components/c-product-grid-item.vue';
  import { Product } from '@/types/product';
  import { ListName } from '@/plugins/google-tag-manager';

  type Setup = {
    productStockStore: ReturnType<typeof useProductStockStore>;
    ListName: typeof ListName;
  }

  type Data = {
    results: Product[];
  }

  /**
   * Renders the substitute products modal.
   */
  export default defineComponent({
    name: 'c-substitute-products-modal',

    components: {
      cProductGridItem,
      eLoader,
      cModal,
    },

    // props: {},
    // emits: {},

    setup(): Setup {
      return {
        productStockStore: useProductStockStore(),
        ListName,
      };
    },
    data(): Data {
      return {
        results: [],
      };
    },

    computed: {
      isOpen: {
        get(): boolean {
          return !!this.productStockStore.substituteProductsModalProduct;
        },

        set(isOpen: boolean) {
          if (!isOpen) {
            this.results = [];
            this.productStockStore.substituteProductsModalProduct = undefined;
          }
        },
      },
    },
    watch: {
      'productStockStore.substituteProductsModalProduct': function(product?: Product) {
        const { substituteProducts } = product || {};

        if (Array.isArray(substituteProducts)) {
          this.productStockStore.apiFetchSubstituteProducts(substituteProducts.map(substituteProduct => ({
            code: substituteProduct.code,
          }))).then((response) => {
            const { results } = response.data?.data || {};

            if (Array.isArray(results)) {
              this.results = results;
            }
          });
        }
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-substitute-products-modal {
    &__list {
      display: grid;
      grid-gap: variables.$spacing--40 variables.$spacing--20;

      @include mixins.media(xs) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mixins.media(md) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
</style>
