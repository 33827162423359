<template>
  <ul :class="b()">
    <li v-for="(link, index) in mediaLinks"
        :key="index"
        :class="b('item')"
    >
      <a v-bind="cmsLinkAttributes(link)"
         :class="b('link')"
         :download="addDownloadAttribute && link.linkTarget !== HybrisLinkTarget.Modal ? '' : undefined"
         @click="onClick($event, link)"
      >
        <e-icon icon="i-cad" size="20" />
        {{ link.name }}
      </a>
      <span v-if="link.fileSize" :class="b('file-size-wrapper')">
        <span :class="b('file-size')">{{ link.fileSize }}</span>
      </span>
    </li>
  </ul>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { HybrisLinkTarget, MediaLinkType } from '@/setup/globals';
  import useSessionStore from '@/stores/session';
  import eIcon from '@/elements/e-icon.vue';
  import { cIframeModalState } from '@/components/c-iframe-modal.vue';
  import cmsLinkAttributes from '@/helpers/cms-link-attributes';
  import { ProductMediaLink } from '@/types/product-media-link';

  type Setup = {
    cmsLinkAttributes: typeof cmsLinkAttributes;
    sessionStore: ReturnType<typeof useSessionStore>;
    HybrisLinkTarget: typeof HybrisLinkTarget;
  }

  // interface Data {}

  /**
   * Renders a list of links with icons.
   */
  export default defineComponent({
    name: 'c-media-links',

    components: {
      eIcon,
    },

    props: {
      /**
       * Expects an array with media links.
       */
      mediaLinks: {
        type: Array as PropType<ProductMediaLink[]>,
        required: true,
      },

      /**
       * Adds `download`-Attribute to all links if enabled.
       */
      addDownloadAttribute: {
        type: Boolean,
        default: false,
      },
    },
    // emits: {},

    setup(): Setup {
      return {
        cmsLinkAttributes,
        sessionStore: useSessionStore(),
        HybrisLinkTarget,
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      onClick(event: Event, link: ProductMediaLink): void {
        const isCadenasModalLink = link.mediaLinkType === MediaLinkType.Cadenas && link.linkTarget === HybrisLinkTarget.Modal;

        if (isCadenasModalLink && !this.sessionStore.user.dataProtectionAccepted) {
          event.preventDefault();
          event.stopPropagation();

          this.$confirmation({
            title: null,
            message: this.$t('global.acceptCadenasDataProtection')
              .replace('%DATA_PROTECTION_LINK%', this.sessionStore.links.dataProtectionLink.url),
          }).then(() => {
            this.sessionStore.isGlobalLoadingVisible = true;

            this.sessionStore.apiAcceptCadenasDataProtectionPolicy().finally(() => {
              this.sessionStore.isGlobalLoadingVisible = false;
              this.sessionStore.user.dataProtectionAccepted = true;

              cIframeModalState.url = link.url;
            });
          }).catch(() => {
            // Do nothing.
          });
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  // @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-media-links {
    $this: &;

    &__item {
      display: flex;
      gap: variables.$spacing--10;
    }

    &__link {
      display: inline-flex;
      gap: variables.$spacing--10;
      align-items: center;
      padding-bottom: variables.$spacing--5;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }
    }

    &__file-size-wrapper {
      position: relative;
      display: flex;

      #{$this}__file-size {
        padding-left: variables.$spacing--10;
        color: variables.$color-primary--1;
      }

      #{$this}__file-size::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: '|';
      }
    }
  }
</style>
