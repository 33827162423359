<template>
  <header :class="b({ shadow: isSticky && !sessionStore.disableHeaderShadow })" :style="rootStyles">
    <div :class="b('inner')">
      <section v-if="isDesktop"
               ref="meta"
               :class="b('meta')"
      >
        <c-service-navigation
          @click-contact-person-button="showContactPersonOverlay = !showContactPersonOverlay"
          @click-account-navigation-button="showAccountNavigation = !showAccountNavigation"
        />
      </section>
      <section :class="b('main')">
        <a :class="b('logo')" href="/">
          <img v-bind="sessionStore.getLogoAttributes" :alt="$t('c-header.homepageLinkTitle')">
        </a>
        <button ref="navigationToggle"
                :class="b('navigation-toggle', { open: showMainNavigation })"
                :aria-label="$t('c-header.buttonToggleMainNavigation')"
                type="button"
                @click="showMainNavigation = !showMainNavigation"
        >
          <span :class="b('navigation-toggle-label')"
                :aria-label="$t('c-header.assortment')"
          >
            {{ $t('c-header.assortment') }}</span>
          <e-icon :icon="isDesktop ? 'i-arrow--down' : 'i-burger-and-search'"
                  size="43 30"
          />
        </button>
        <div v-if="isDesktop" :class="b('search')">
          <c-product-search />
        </div>
        <ul :class="b('quick-links')">
          <li v-if="showRecommendedRetailPriceToggle" :class="b('quick-links-item', { recommendedRetailPrice: true })">
            <label v-tooltip="$t('c-header.retailPriceToggleLabel')" :class="b('retail-price-toggle')">
              <input v-model="sessionStore.showOnlyRecommendedRetailPrice"
                     :class="b('retail-price-toggle-checkbox')"
                     type="checkbox"
                     value
              >
              <span class="invisible">{{ $t('c-header.retailPriceToggleLabel') }}</span>
              <e-icon icon="i-price-tag" />
            </label>
          </li>
          <li v-if="$viewport.isMd && sessionStore.activeLayout !== Layout.Cart" :class="b('quick-links-item')">
            <a v-bind="cmsLinkAttributes(sessionStore.links.compareProductsLink)"
               :aria-label="$t('global.productComparison')"
               :class="b('collective-orders-link')"
            >
              <e-icon icon="i-comparison"
                      size="33"
              />
              <span v-if="productComparisonStore.productCodes.length" :class="b('quick-link-badge')">
                {{ productComparisonStore.productCodes.length }}
              </span>
            </a>
          </li>
          <li
            v-if="$viewport.isLg && showCollectiveOrdersQuickLink && sessionStore.activeLayout !== Layout.Cart"
            :class="b('quick-links-item')"
          >
            <a v-bind="cmsLinkAttributes(sessionStore.dashboard.collectiveOrdersLink)"
               :aria-label="$t('global.collectiveOrders')"
               :class="b('collective-orders-link')"
            >
              <e-icon icon="i-collective-order"
                      size="33"
              />
              <span v-if="sessionStore.dashboard.numberOfCollectiveCartsOpen " :class="b('quick-link-badge')">
                {{ sessionStore.dashboard.numberOfCollectiveCartsOpen }}
              </span>
            </a>
          </li>
          <template v-if="!sessionStore.flags.isComnormUser">
            <li
              v-if="$viewport.isLg && checkoutStore.getCartEntriesAmount && sessionStore.activeLayout !== Layout.Cart"
              :class="b('quick-links-item')"
            >
              <!-- ID used for outside click handling in `c-mini-cart` -->
              <button :id="b('mini-cart-toggle')"
                      :aria-label="$t('c-header.buttonShowMiniCart')"
                      :class="b('mini-cart-toggle', { active: showMiniCart })"
                      type="button"
                      @click="showMiniCart = !showMiniCart"
              >
                <e-icon icon="i-cart" :class="b('mini-cart-toggle-icon-cart')" size="27" />
                <e-icon icon="i-arrow--down" :class="b('mini-cart-toggle-icon-arrow')" size="25" />
              </button>
            </li>
            <li v-if="sessionStore.links.cartLink" :class="b('quick-links-item')">
              <a :href="addContextPathToUrl(sessionStore.links.cartLink.url)"
                 :aria-label="$t('c-header.linkCart')"
                 :class="b('cart-link')"
              >
                <template v-if="isDesktop">
                  <span
                    v-if="showTotalCartPrice"
                    v-price.prefixCurrency="checkoutStore.cart?.totalPriceWithoutFreight?.value"
                    :class="b('cart-link-total-price')"
                    aria-hidden="true"
                  ></span>
                  <e-icon
                    v-else
                    icon="i-cart"
                    size="27"
                  />
                  <span
                    v-if="checkoutStore.getCartEntriesAmount > 0"
                    :class="b('quick-link-badge')"
                    aria-hidden="true"
                  >
                    {{ checkoutStore.getCartEntriesAmount }}
                  </span>
                  <e-icon icon="i-arrow--right" size="27" />
                </template>
                <template v-else>
                  <e-icon icon="i-cart"
                          size="33"
                  />
                  <span
                    v-if="checkoutStore.getCartEntriesAmount > 0"
                    :class="b('quick-link-badge')"
                    aria-hidden="true"
                  >
                    {{ checkoutStore.getCartEntriesAmount }}
                  </span>
                </template>
              </a>
            </li>
          </template>
        </ul>
      </section>
    </div>

    <e-global-backdrop v-if="$viewport.isMd" />

    <transition :name="b('transition', { slideTop: $viewport.isMd, slideRight: !$viewport.isMd })"
                @after-enter="onBeforeEnter"
                @after-leave="onAfterLeave"
    >
      <c-main-navigation v-if="showMainNavigation"
                         v-outside-click="{ handler: onOutsideClick, excludeRefs: ['navigationToggle'] }"
                         ref="navigation"
                         :class="b('navigation')"
      >
        <div v-if="!isDesktop" :class="b('meta', { mobile: true })">
          <button :class="b('navigation-toggle', { mobile: true })"
                  :aria-label="$t('c-header.navigationCloseButtonLabel')"
                  type="button"
                  @click="showMainNavigation = false"
          >
            <e-icon icon="i-close" />
          </button>
          <c-product-search />
          <c-service-navigation
            :is-mobile-account-navigation-visible="showAccountNavigation"
            @click-contact-person-button="showContactPersonOverlay = !showContactPersonOverlay"
            @click-account-navigation-button="showAccountNavigation = !showAccountNavigation"
          />
        </div>
        <template #navigation v-if="showAccountNavigation && !$viewport.isMd">
          <c-account-navigation>
            <template #extended-service-navigation>
              <div :class="b('extended-service-navigation')">
                <a v-if="sessionStore.getIsLoggedIn && !sessionStore.getIsPunchoutOrOciUser"
                   :href="sessionStore.links.loginLogoutLink.url"
                   :class="b('mobile-profile-logout-link')"
                >
                  <e-icon icon="i-exit" size="25" />
                  {{ $t('c-service-navigation.linkLogout') }}
                </a>
                <c-language-switcher :class="b('language-switcher', { horizontal: true })" />
              </div>
            </template>
          </c-account-navigation>
        </template>
      </c-main-navigation>
    </transition>
    <transition :name="b('transition', { slideTop: true })"
                @before-enter="onBeforeEnter"
                @after-leave="onAfterLeave"
    >
      <c-mini-cart v-if="showMiniCart"
                   :class="b('overlay', { miniCart: true })"
                   @outside-click="showMiniCart = false"
      />
    </transition>
    <transition :name="b('transition', { slideTop: $viewport.isMd })"
                @before-enter="onBeforeEnter"
                @after-leave="onAfterLeave"
    >
      <c-contact-person-overlay v-if="showContactPersonOverlay"
                                :class="b('overlay', { contactPerson: true, })"
                                @close="showContactPersonOverlay = false"
      />
    </transition>
    <transition :name="b('transition', { slideTop: $viewport.isMd })"
                @before-enter="onBeforeEnter"
                @after-leave="onAfterLeave"
    >
      <c-account-navigation v-if="showAccountNavigation && $viewport.isMd"
                            :class="b('overlay', { accountNavigation: true })"
                            @outside-click="showAccountNavigation = false"
                            @close="showAccountNavigation = false"
      >
        <template #extended-service-navigation>
          <div :class="b('extended-service-navigation')">
            <a v-if="sessionStore.getIsLoggedIn && !sessionStore.getIsPunchoutOrOciUser"
               :href="sessionStore.links.loginLogoutLink.url"
               :class="b('mobile-profile-logout-link')"
            >
              <e-icon icon="i-exit" size="25" />
              {{ $t('c-service-navigation.linkLogout') }}
            </a>
            <c-language-switcher v-if="!$viewport.isMd" :class="b('language-switcher', { horizontal: true })" />
          </div>
        </template>
      </c-account-navigation>
    </transition>
    <e-global-loader />
    <c-search-suggestions :class="b('search-suggestions')" />
    <c-global />
    <e-back-top />
    <c-notification-container v-if="notificationStore.showDefaultGlobalNotifications"
                              :class="b('notifications')"
    />
  </header>
</template>

<script lang="ts">
  import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
  import { defineComponent, Ref, ref } from 'vue';
  import { Layout } from '@/setup/globals';
  import useSessionStore from '@/stores/session';
  import useCheckoutStore from '@/stores/checkout';
  import useProductComparisonStore from '@/stores/product-comparison';
  import useNotificationStore from '@/stores/notification';
  import cAccountNavigation from '@/components/c-account-navigation.vue';
  import eGlobalLoader from '@/elements/e-global-loader.vue';
  import cNotificationContainer from '@/components/c-notification-container.vue';
  import eBackTop from '@/elements/e-back-top.vue';
  import cProductSearch from '@/components/c-product-search.vue';
  import cSearchSuggestions from '@/components/c-search-suggestions.vue';
  import cLanguageSwitcher from '@/components/c-language-switcher.vue';
  import eGlobalBackdrop from '@/elements/e-global-backdrop.vue';
  import cServiceNavigation from '@/components/c-service-navigation.vue';
  import cMainNavigation from '@/components/c-main-navigation.vue';
  import eIcon from '@/elements/e-icon.vue';
  import cMiniCart from '@/components/c-mini-cart.vue';
  import cContactPersonOverlay from '@/components/c-contact-person-overlay.vue';
  import cGlobal from '@/components/c-global.vue';
  import addContextPathToUrl from '@/helpers/add-context-path-to-url';
  import tooltipDirective from '@/plugins/tooltip/directives/directive';
  import cmsLinkAttributes from '@/helpers/cms-link-attributes';
  import { bodyScrollOptions } from '@/setup/options';
  import getScrollBarWidth from '@/helpers/scrollbar-width';

  interface Setup {
    meta: Ref<HTMLDivElement | undefined>;
    sessionStore: ReturnType<typeof useSessionStore>;
    checkoutStore: ReturnType<typeof useCheckoutStore>;
    notificationStore: ReturnType<typeof useNotificationStore>;
    productComparisonStore: ReturnType<typeof useProductComparisonStore>;
    addContextPathToUrl: typeof addContextPathToUrl;
    cmsLinkAttributes: typeof cmsLinkAttributes;
    Layout: typeof Layout;
  }

  interface Data {
    metaSectionHeight: number;
    resizeObserver?: ResizeObserver;
    intersectionObserver?: IntersectionObserver;
    showMainNavigation: boolean;
    showMiniCart: boolean;
    showContactPersonOverlay: boolean;
    showAccountNavigation: boolean;
    isSticky: boolean;
  }

  interface RootStyles {
    [key: string]: string;
  }

  export interface LogoAttributes {
    src: string;
    width: string;
    height: string;
  }

  const SSR_STATIC_MINIMAL_HEADER_SELECTOR = '.c-header--static-minimal';

  /**
   * Renders a layout header element with support for sticky state.
   */
  export default defineComponent({
    name: 'c-header',

    components: {
      cGlobal,
      cContactPersonOverlay,
      cMiniCart,
      eGlobalBackdrop,
      cSearchSuggestions,
      eIcon,
      cMainNavigation,
      eGlobalLoader,
      cNotificationContainer,
      eBackTop,
      cProductSearch,
      cServiceNavigation,
      cAccountNavigation,
      cLanguageSwitcher,
    },

    directives: {
      tooltip: tooltipDirective,
    },

    // props: {},
    setup(): Setup {
      return {
        meta: ref(),
        sessionStore: useSessionStore(),
        checkoutStore: useCheckoutStore(),
        notificationStore: useNotificationStore(),
        productComparisonStore: useProductComparisonStore(),
        addContextPathToUrl,
        cmsLinkAttributes,
        Layout,
      };
    },
    data(): Data {
      return {
        metaSectionHeight: 0,
        resizeObserver: undefined,
        showMainNavigation: false,
        showMiniCart: false,
        showContactPersonOverlay: false,
        showAccountNavigation: false,
        intersectionObserver: undefined,
        isSticky: false,
      };
    },

    computed: {
      /**
       * Returns dynamic styles for the root element.
       */
      rootStyles(): RootStyles {
        return {
          '--c-header__meta-height': `-${this.metaSectionHeight}px`,
        };
      },

      /**
       * Returns whether to show the global backdrop.
       */
      showGlobalBackDrop(): boolean {
        return this.showMiniCart || this.showContactPersonOverlay || this.showAccountNavigation;
      },

      showRecommendedRetailPriceToggle(): boolean {
        return import.meta.env.VITE_ENABLE_RECOMMENDED_RETAIL_PRICE === '1' && this.sessionStore.getIsLoggedIn;
      },

      showCollectiveOrdersQuickLink(): boolean {
        return this.sessionStore.flags.showCollectiveOrders
          && this.sessionStore.activeLayout !== Layout.CollectiveOrderOverview;
      },

      isDesktop() {
        return this.$viewport.isMd;
      },

      showTotalCartPrice(): boolean {
        return !!(this.checkoutStore.cart?.showPrices
          && this.checkoutStore.getCartEntriesAmount
          && !this.sessionStore.flags.retailConnectEnabled
        );
      },
    },
    watch: {
      /**
       * En-/disables the body scroll depending on the visibility of the main navigation.
       */
      showMainNavigation(show: boolean): void {
        if (show) {
          window.addEventListener('keydown', this.onKeyDown);
        } else {
          window.removeEventListener('keydown', this.onKeyDown);
          clearAllBodyScrollLocks();
        }
      },

      /**
       * Observes viewport and closes mini cart if it is open and viewport got to small.
       */
      isDesktop(isDesktop: boolean): void {
        if (!isDesktop && (this.showAccountNavigation || this.showContactPersonOverlay)) {
          this.showAccountNavigation = false;
          this.showContactPersonOverlay = false;
        }
      },

      '$viewport.isLg': function(isLg: boolean): void {
        if (!isLg && this.showMiniCart) {
          this.showMiniCart = false;
        }
      },

      /**
       * En-/disables the global backdrop.
       */
      showGlobalBackDrop(show: boolean): void {
        this.sessionStore.isGlobalBackdropVisible = show;
      },
    },

    // beforeCreate() {},
    created() {
      document.body.classList.add(`shop-theme--${this.sessionStore.theme}`); // TODO: Would be preferable if this class could be set by the backend.
    },
    // beforeMount() {},
    mounted() {
      document.querySelector(SSR_STATIC_MINIMAL_HEADER_SELECTOR)?.remove(); // Removes SSR-rendered minimal header.
      this.resizeObserver = new ResizeObserver(this.updateHeightAndPosition);

      this.resizeObserver.observe(this.$el);

      this.updateStickyObserver();

      window.addEventListener('resizeend', this.updateStickyObserver);
      window.addEventListener('scroll', this.onScroll, { passive: true });
      this.updateScrollbarWidth();
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    beforeUnmount() {
      window.removeEventListener('keydown', this.onKeyDown);
      window.removeEventListener('scroll', this.onScroll);
      window.removeEventListener('resizeend', this.updateStickyObserver);

      this.resizeObserver?.disconnect();
    },
    // unmounted() {},

    methods: {
      updateStickyObserver() {
        this.$nextTick(() => {
          const { meta } = this;

          if (meta) {
            this.intersectionObserver = new IntersectionObserver(this.onMetaIntersecting, {
              threshold: 0.1,
            });

            this.intersectionObserver.observe(meta);
          } else {
            this.intersectionObserver?.disconnect();
            this.intersectionObserver = undefined;
          }
        });
      },

      onMetaIntersecting([entry]: IntersectionObserverEntry[]) {
        this.isSticky = !entry.isIntersecting;
      },

      /**
       * Handles the Transition event for entering elements.
       */
      onBeforeEnter(element: Element): void {
        disableBodyScroll(element, bodyScrollOptions);
      },

      /**
       * Handles the Transition event for leaving elements.
       */
      onAfterLeave(element: Element): void {
        enableBodyScroll(element);
      },

      /**
       * Updates height and position definitions.
       *
       * - Updates the header height
       * - Updates the headers vertical anchor position (based on its bottom position)
       *
       * Primarily these definitions are used for aligning the position of other elements to the header.
       */
      updateHeightAndPosition(): void {
        this.metaSectionHeight = this.meta?.getBoundingClientRect()?.height || 0;

        this.updateHeaderHeightVariable();
      },

      onScroll() {
        if (!this.isDesktop) {
          this.isSticky = window.scrollY > 5;
        }

        this.updateHeaderHeightVariable();
      },

      /**
       * Updates the header height variable on the body.
       */
      updateHeaderHeightVariable(): void {
        document.body.style.setProperty('--header-height', `${this.$el.getBoundingClientRect()?.bottom}px`);
      },

      /**
       * Handles outside clicks for the header.
       */
      onOutsideClick(): void {
        if (this.isDesktop) {
          this.showMainNavigation = false;
        }
      },

      /**
       * Handles keyboard events from the window object.
       */
      onKeyDown(event: KeyboardEvent): void {
        switch (event.key) {
          case 'Escape':
            this.showMainNavigation = false;

          // no default
        }
      },

      updateScrollbarWidth(): void {
        document.documentElement.style.setProperty('--scrollbar-width', `${getScrollBarWidth()}px`);
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-header {
    $this: &;
    $top-border__height: 5px;
    $sticky__margin-top: variables.$spacing--10;

    @include mixins.z-index(header);

    position: sticky;
    top: var(--c-header__meta-height, 0);
    grid-area: header;
    margin-bottom: variables.$spacing--15;

    @include mixins.media($media: print) {
      position: relative;
      top: 0;
      page-break-after: avoid;
    }

    &::before {
      @include mixins.media(md) {
        @include mixins.z-index(header);

        position: sticky;
        display: block;
        content: '';
        border-top: $top-border__height solid variables.$color-grayscale--0;
        inset: 0 0 auto;
      }

      @include mixins.media($media: print) {
        content: none;
      }
    }

    &::after { // Backdrop -- required for notifications container.
      @include mixins.z-index(back);

      position: absolute;
      content: '';
      background-color: variables.$color-grayscale--1000;
      inset: 0;
      transition: box-shadow variables.$transition-duration--200;
    }

    &__inner {
      @include mixins.container;
    }

    &__meta {
      @include mixins.media(md) {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        min-height: 55px;
      }

      @include mixins.media(md) {
        // Don't use 'margin' because of JS calculations for the height.
        padding-block: (variables.$spacing--15 - $top-border__height) (variables.$spacing--25 - $sticky__margin-top);
      }

      @include mixins.media($media: print) {
        display: none;
      }

      &--mobile {
        display: grid;
        grid-template-areas:
          'navigationToggle search'
          'service service';
        grid-template-columns: auto 1fr;
        gap: variables.$spacing--25 0;

        .c-product-search {
          grid-area: search;
        }

        .c-service-navigation {
          grid-area: service;
          margin-inline: variables.$spacing--20;
        }
      }
    }

    &__main {
      @include mixins.grid;

      align-items: flex-end;
      margin-top: variables.$spacing--10;
      padding-bottom: variables.$spacing--10;

      @include mixins.media(md) {
        margin-top: $sticky__margin-top;
        padding-bottom: variables.$spacing--15;
      }
    }

    &__logo {
      display: block;
      grid-column: 5 / span 4;
      place-self: center center;

      @include mixins.media(md) {
        justify-self: initial;
        grid-column: span 2;
      }

      &:focus {
        outline: 1px dotted variables.$color-grayscale--400;
      }
    }

    &__search {
      display: block;
      grid-column-end: span 2;

      @include mixins.media(md) {
        grid-column-end: span 3;
      }

      @include mixins.media(xl) {
        grid-column-end: span 4;
      }

      @include mixins.media($media: print) {
        display: none;
      }
    }

    &__quick-links {
      display: flex;
      grid-column-end: span 4;
      column-gap: variables.$spacing--25;
      justify-self: end;

      @include mixins.media(md) {
        grid-column-end: span 4;
      }

      @include mixins.media(lg) {
        grid-column-end: span 5;
      }

      @include mixins.media(xl) {
        grid-column-end: span 4;
      }

      @include mixins.media($media: print) {
        display: none;
      }

      &:empty {
        display: none;
      }
    }

    &__extended-service-navigation {
      @include mixins.media($down: sm) {
        display: flex;
        justify-content: space-between;
        padding-bottom: variables.$spacing--20;
        background: variables.$color-grayscale--1000;
      }

      #{$this}__language-switcher {
        &--horizontal {
          position: relative;
          display: flex;
          align-items: center;
          text-transform: uppercase;

          .c-language-switcher__toggle {
            display: none;
          }

          .c-language-switcher__list {
            position: relative;
            gap: variables.$spacing--15;
            flex-direction: row;
            height: auto;
            transition: none;
          }
        }
      }
    }

    &__mobile-profile-logout-link {
      @include mixins.font(variables.$font-size--16, null, variables.$font-weight--bold);

      display: flex;
      align-items: center;
      column-gap: variables.$spacing--10;

      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }
    }

    &__notifications {
      position: absolute;
      inset-inline: 0;
    }

    &__navigation-toggle {
      @include mixins.font(variables.$font-size--25, 32px); // line-height is used to control text/border-bottom spacing.

      position: relative;
      display: flex;
      grid-column-end: span 4;
      gap: variables.$spacing--10;
      align-items: center;
      order: -1;
      cursor: pointer;
      font-weight: variables.$font-weight--bold;
      justify-self: start;

      @include mixins.media(sm) {
        @include mixins.font(variables.$font-size--20);
      }

      @include mixins.media(md) {
        @include mixins.font(variables.$font-size--25);

        grid-column-end: span 3;
        order: initial;
        border-bottom: 3px solid variables.$color-grayscale--0;
      }

      @include mixins.media(lg) {
        grid-column-end: span 2;
      }

      @include mixins.media($media: print) {
        display: none;
      }

      .e-icon {
        transition: transform variables.$transition-duration--300;

        @include mixins.media(md) {
          max-width: 0.9em;
          height: auto;
        }
      }

      &--open {
        .e-icon {
          @include mixins.media(md) {
            transform: rotate(180deg);
          }
        }
      }

      &--mobile {
        display: flex;
        grid-area: navigationToggle;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 60px;
        background: variables.$color-grayscale--0;
        color: variables.$color-grayscale--1000;
      }
    }

    &__navigation-toggle-label {
      display: none;

      @include mixins.media(md) {
        display: inline;
      }
    }

    &__navigation {
      position: fixed;
      inset: 0;

      @include mixins.media(md) {
        position: absolute;
        inset: auto 0;
        max-height: calc(100dvh - var(--header-height, variables.$e-header__height--fallback));
      }
    }

    &__overlay {
      position: fixed;
      top: var(--header-height);
      inset-inline: 0;
      height: calc(100dvh - var(--header-height, variables.$e-header__height--fallback));

      @include mixins.media(md) {
        height: auto;
        max-height: calc(100dvh - var(--header-height, variables.$e-header__height--fallback));
      }

      &--contact-person {
        @include mixins.media($down: sm) {
          @include mixins.z-index(mobileContactPersonOverlay);
        }
      }
    }

    &__quick-links-item {
      position: relative;

      &:not(:last-of-type)::after {
        position: absolute;
        top: 0;
        right: - variables.$spacing--15;
        content: '';
        width: 2px;
        height: 100%;
        background-color: variables.$color-grayscale--0;
      }

      &--recommended-retail-price {
        display: flex;
        align-items: center;
      }
    }

    &__mini-cart-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
      column-gap: variables.$spacing--10;

      &:hover,
      &:focus {
        #{$this}__mini-cart-toggle-icon-cart,
        #{$this}__mini-cart-toggle-icon-arrow {
          color: variables.$color-primary--1;
        }
      }

      &--active {
        #{$this}__mini-cart-toggle-icon-cart {
          color: variables.$color-primary--1;
        }

        #{$this}__mini-cart-toggle-icon-arrow {
          transform: rotate(180deg);
        }
      }
    }

    &__mini-cart-toggle-icon-arrow {
      transition: transform variables.$transition-duration--300 ease-in-out;
    }

    &__collective-orders-link,
    &__cart-link {
      display: flex;
      align-items: center;
      column-gap: variables.$spacing--5;
    }

    &__cart-link-total-price {
      @include mixins.font(variables.$font-size--20, null, variables.$font-weight--bold);
    }

    &__quick-link-badge {
      @include mixins.font(variables.$font-size--14, 16px, variables.$font-weight--bold);

      align-self: flex-start;
      padding: variables.$spacing--2;
      background-color: variables.$color-primary--1;
      color: variables.$color-grayscale--1000;

      @include mixins.media($down: sm) {
        position: absolute;
        top: -7px;
        right: -5px;
      }
    }

    &__search-suggestions {
      @include mixins.media($down: sm) {
        @include mixins.z-index(mobileSearchSuggestions);
      }
    }

    &__retail-price-toggle {
      cursor: pointer;
    }

    &__retail-price-toggle-checkbox {
      ~ * {
        color: variables.$color-grayscale--400;
      }

      &:checked ~ * {
        color: variables.$color-primary--1;
      }
    }

    &__transition--slide-top-enter-active,
    &__transition--slide-top-leave-active {
      @include mixins.z-index(back);

      transition: transform variables.$transition-duration--300 ease;
    }

    &__transition--slide-top-enter-from,
    &__transition--slide-top-leave-to {
      transform: translateY(-100%);
    }

    &__transition--slide-right-enter-active,
    &__transition--slide-right-leave-active {
      width: 100dvw;
      transition: left variables.$transition-duration--500 ease;

      @include mixins.media(md) {
        @include mixins.z-index(back);
      }
    }

    &__transition--slide-right-enter-from,
    &__transition--slide-right-leave-to {
      left: 100%;
    }

    &--shadow::after {
      box-shadow: 0 3px 3px -3px variables.$color-grayscale--400;

      @include mixins.media($media: print) {
        box-shadow: none;
      }
    }
  }
</style>
