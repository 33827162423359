<template>
  <div :class="b({ listStyle })">
    <div v-if="showHeader" :class="b('head')">
      <div :class="b('amount')">
        {{ amount ? $tc('c-product-grid.amountOfProducts', amount, { count: amount }) : '' }}
      </div>
      <div v-if="!$viewport.isSm" :class="b('list-style-toggle')">
        <button :class="b('button-list-type', { active: listStyle === 'grid' })"
                :aria-label="$t('c-product-grid.buttonListTypeGrid')"
                type="button"
                @click="setListStyle('grid')"
        >
          <e-icon icon="i-grid" size="20" />
        </button>
        <button :class="b('button-list-type', { active: listStyle === 'list' })"
                :aria-label="$t('c-product-grid.buttonListTypeList')"
                type="button"
                @click="setListStyle('list')"
        >
          <e-icon icon="i-list" size="20" />
        </button>
      </div>
    </div>
    <ul :class="b('list')">
      <!-- @slot Slot is used for rendering placeholders -->
      <slot></slot>
      <li v-for="product in filteredProducts"
          :key="product.code"
          :class="b('list-item')"
      >
        <c-product-grid-item :product="product"
                             :variant="listStyle === 'list' ? 'minified' : 'default'"
                             :gtm-list-name="gtmListName"
                             :display-variants-in-modal="displayVariantsInModal"
        />
      </li>
    </ul>

    <!-- Optional show more button -->
    <div v-if="showMoreIsVisible" :class="b('show-more')">
      <e-button type="button" @click="showAll = !showAll">
        {{ $t(showAll ? 'global.collapse' : 'global.expand') }}
      </e-button>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { IS_STORAGE_AVAILABLE, StorageKey } from '@/setup/globals';
  import eIcon from '@/elements/e-icon.vue';
  import cProductGridItem from '@/components/c-product-grid-item.vue';
  import eButton from '@/elements/e-button.vue';
  import { ListName } from '@/plugins/google-tag-manager';
  import { Product } from '@/types/product';

  // interface Setup {}
  interface Data {
    internalListStyle: string;
    showAll: boolean;
  }

  /**
   * Renders a grid of product tiles.
   */
  export default defineComponent({
    name: 'c-product-grid',

    components: {
      eButton,
      eIcon,
      cProductGridItem,
    },

    props: {
      /**
       * Expects a list of products.
       */
      products: {
        type: Array as PropType<Product[]>,
        required: true,
      },

      /**
       * Allows passing the amount of products.
       */
      amount: {
        type: Number,
        default: undefined,
      },

      /**
       * Expects a GTM list name to be passed.
       */
      gtmListName: {
        type: String as PropType<ListName>,
        required: true,
      },

      /**
       * Defines if the header of the list should be shown.
       */
      showHeader: {
        type: Boolean,
        default: true,
      },

      /**
       * Defines if there ary only a certain amount of products visible initially with a showMore button.
       */
      hasLimitation: {
        type: Boolean,
        default: false,
      },

      /**
       * Defines if a product variant should display the variant selection in a modal.
       */
      displayVariantsInModal: {
        type: Boolean,
        default: false,
      },
    },
    // emits: [],

    // setup(): Setup {},
    data(): Data {
      return {
        internalListStyle: (IS_STORAGE_AVAILABLE && window.localStorage.getItem(StorageKey.ProductGridListStyle)) || 'grid',
        showAll: false,
      };
    },

    computed: {
      /**
       * Returns list style based on setting and viewport.
       */
      listStyle(): 'grid' | 'list' {
        return this.internalListStyle === 'list' && !this.$viewport.isSm
          ? 'list'
          : 'grid';
      },

      initialVisibleProducts(): number {
        let itemsPerRow;
        const visibleRows = 2;

        switch (this.$viewport.currentViewport) {
          case 'xl':
            itemsPerRow = 6;
            break;

          case 'lg':
            itemsPerRow = 5;
            break;

          case 'md':
            itemsPerRow = 4;
            break;

          case 'sm':
            itemsPerRow = 3;
            break;

          case 'xs':
            itemsPerRow = 2;
            break;

          default:
            itemsPerRow = 1;
        }

        return itemsPerRow * visibleRows;
      },

      showMoreIsVisible(): boolean {
        if (!this.hasLimitation) {
          return false;
        }

        return this.products.length > this.initialVisibleProducts;
      },

      filteredProducts(): Product[] {
        return this.showMoreIsVisible && !this.showAll
          ? this.products.slice(0, this.initialVisibleProducts)
          : this.products;
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      /**
       * Sets the list style.
       */
      setListStyle(type: 'list' | 'grid'): void {
        this.internalListStyle = type;

        if (IS_STORAGE_AVAILABLE) {
          window.localStorage.setItem(StorageKey.ProductGridListStyle, type);
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-product-grid {
    $this: &;

    &__head {
      display: grid;
      grid-template-columns: 1fr auto;
      margin-bottom: variables.$spacing--30;
      padding-top: variables.$spacing--15;
      border-top: 4px solid variables.$color-grayscale--0;
    }

    &__list-style-toggle {
      display: grid;
      grid-column-gap: variables.$spacing--20;
      grid-template-columns: 1fr 1fr;
    }

    &__button-list-type {
      cursor: pointer;

      &--active,
      &:hover,
      &:focus {
        color: variables.$color-primary--1;
      }
    }

    &__list {
      display: grid;
      grid-gap: variables.$spacing--40 variables.$spacing--20;
      grid-template-columns: 1fr;
    }

    &__show-more {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: variables.$spacing--30;
    }

    &--list-style-grid {
      #{$this}__list {
        overflow: hidden;

        @include mixins.media(xs) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include mixins.media(sm) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @include mixins.media(md) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @include mixins.media(lg) {
          grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        @include mixins.media(xl) {
          grid-template-columns: repeat(6, minmax(0, 1fr));
        }
      }

      #{$this}__list-item:not(:last-of-type) .c-product-grid-item::after {
        right: 0;
        bottom: - variables.$spacing--20;
        left: 0;
        height: 1px;

        @include mixins.media(xs) {
          inset: 0 (- variables.$spacing--10) 0 auto;
          width: 1px;
          height: auto;
        }
      }
    }

    &--list-style-list {
      #{$this}__list-item:not(:last-of-type) .c-product-grid-item::after {
        right: 0;
        bottom: - variables.$spacing--20;
        left: 0;
        height: 1px;
      }

      .c-product-placeholder {
        min-height: 100px;
      }
    }

    .c-product-grid-item::after {
      position: absolute;
      content: '';
      background-color: variables.$color-grayscale--0;
    }

    .c-add-to-cart .e-quantity-select {
      flex: 1 0 auto;
    }
  }
</style>
