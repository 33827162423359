<template>
  <div :class="b()">
    <c-tabs :tabs="tabs"
            padding="800"
            @change="setCurrentTabIndex"
    >
      <template v-for="(tabComponent, index) in tabComponents"
                :key="index"
                #[index]
      >
        <template v-if="activeTabIndex === index">
          <!-- Slider View -->
          <c-slider v-if="viewType === ViewType.SLIDER"
                    :class="b('slider')"
                    :navigation-mode="$viewport.isMd ? NavigationMode.Page : NavigationMode.Slide"
                    :embla-options="{ align: 'center' }"
                    :variant="SliderVariant.ProductSlider"
          >
            <template v-if="tabComponent.data?.results?.length">
              <c-slider-item-1 v-for="item in tabComponent.data.results"
                               :key="item.code"
                               :product="item"
              />
            </template>
          </c-slider>

          <!-- Grid View -->
          <c-product-grid
            v-if="viewType === ViewType.GRID"
            :products="tabComponent.data.results"
            :gtm-list-name="ListName.ProductSlider"
            :show-header="false"
            has-limitation
            display-variants-in-modal
          />
        </template>
      </template>
    </c-tabs>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import cTabs, { Tab } from '@/components/c-tabs.vue';
  import cSlider, { NavigationMode, SliderVariant } from '@/components/c-slider.vue';
  import cSliderItem1 from '@/components/c-slider-item-1.vue';
  import cProductGrid from '@/components/c-product-grid.vue';
  import mapCmsTabs from '@/helpers/map-cms-tabs';
  import { Product } from '@/types/product';
  import { CmsTab } from '@/types/cms-tab';
  import { HybrisType } from '@/setup/globals';
  import { ListName } from '@/plugins/google-tag-manager';

  export interface ProductSliderTabData {
    results: Product[];
    collapsed: boolean;
    showCategoriesWithImages: boolean;
    showPrices: boolean;
  }

  export interface ProductSliderTab {
    headline: string;
    data: ProductSliderTabData;
    order?: number;
    type: HybrisType.ProductSliderTab;
  }

  export enum ViewType {
    SLIDER = 'SLIDER',
    GRID = 'GRID',
  }

  interface Setup {

    /**
     * Defines the available and active tabs, including their corresponding tab titles.
     */
    tabs: Tab[];

    /**
     * Defines the possible component inside a tab.
     */
    tabComponents: ProductSliderTab[];

    NavigationMode: typeof NavigationMode;
    SliderVariant: typeof SliderVariant;
    ViewType: typeof ViewType;
    ListName: typeof ListName;
  }

  interface Data {
    activeTabIndex: number | null;
  }

  /**
   * The tab component allows to navigate through multiple tabs containing a product slider.
   */
  export default defineComponent({
    name: 'c-cms-product-slider-tabs',

    components: {
      cProductGrid,
      cTabs,
      cSlider,
      cSliderItem1,
    },

    props: {
      /**
       * The data object for the cms tab component.
       */
      data: {
        type: Object as PropType<CmsTab>,
        required: true,
      },

      /**
       * Defines if the items are rendered as grid or as a slider (default).
       */
      viewType: {
        type: String as PropType<ViewType>,
        default: ViewType.SLIDER,
      },
    },
    // emits: {},

    setup(props): Setup {
      // eslint-disable-next-line vue/no-setup-props-destructure -- prop does not need to be reactive.
      const tabComponents = props.data.data.tabs;

      tabComponents.sort((a, b) => (a.order || 0) - (b.order || 0));

      const tabs = mapCmsTabs(tabComponents);

      return {
        NavigationMode,
        SliderVariant,
        tabs,
        tabComponents,
        ViewType,
        ListName,
      };
    },
    data(): Data {
      return {
        activeTabIndex: 0,
      };
    },
    methods: {
      /**
       * Sets the index of the current clicked tab.
       */
      setCurrentTabIndex(tab: Tab): void {
        this.activeTabIndex = Number(tab.id);
      },
    },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .c-cms-product-slider-tabs {
    margin-bottom: variables.$spacing--40;

    &__slider {
      margin-inline: - variables.$spacing--20;

      @include mixins.media(md) {
        margin: 0;
      }

      .c-slider-item-1:first-child {
        @include mixins.media(md) {
          margin-left: - variables.$spacing--10;
        }
      }
    }
  }
</style>
