<template>
  <div :class="b()">
    <c-breadcrumb v-if="$viewport.isSm" />
    <!-- eslint-disable-next-line vue/no-v-html -->
    <h1 v-html="productResultStore.getCategoryName"></h1>
    <div v-if="productResultStore.getSearchResult?.categoryText" :class="b('category-text-wrapper')">
      <c-collapse-text :lines-visible="3">
        <!-- eslint-disable vue/no-v-html -->
        <div v-if="productResultStore.getSearchResult?.categoryText"
             v-html="productResultStore.getSearchResult.categoryText"
             :class="b('category-text')"
             class="wysiwyg"
        ></div>
        <!-- eslint-enable vue/no-v-html -->
      </c-collapse-text>
    </div>
    <c-product-result :gtm-list-name="ListName.CategoryResults" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { Layout } from '@/setup/globals';
  import useProductResultStore from '@/stores/product-result';
  import cBreadcrumb from '@/components/c-breadcrumb.vue';
  import cProductResult from '@/components/c-product-result.vue';
  import cCollapseText from '@/components/c-collapse-text.vue';
  import setActiveLayout from '@/compositions/set-active-layout';
  import { ListName } from '@/plugins/google-tag-manager';

  const layout = Layout.CategoryResult;

  interface Setup {
    productResultStore: ReturnType<typeof useProductResultStore>;
    ListName: typeof ListName;
  }
  // interface Data {}

  /**
   * Renders the category result page.
   *
   * **WARNING: uses 'v-html'. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: layout,
    components: {
      cCollapseText,
      cProductResult,
      cBreadcrumb,
    },

    // components: {},

    // props: {},
    // emits: {},

    setup(): Setup {
      setActiveLayout(layout);

      return {
        productResultStore: useProductResultStore(),
        ListName,
      };
    },
    // data(): Data {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/variables';
  @use '@/setup/scss/mixins';

  .l-category-result {
    &__category-text-wrapper {
      margin-bottom: variables.$spacing--20;
      padding-bottom: variables.$spacing--15;
      border-bottom: 4px solid variables.$color-grayscale--0;

      .c-collapse-text {
        @include mixins.media(lg) {
          max-width: 75%;
        }
      }
    }
  }
</style>
