<template>
  <c-modal :is-open="show"
           :title="title"
           :close-on-outside-click="false"
           :is-closable="false"
           size="800"
  >
    <!-- eslint-disable vue/no-v-html -->
    <p v-if="options?.message"
       v-html="options.message"
       :class="b('message')"
    ></p>
    <!-- eslint-enable vue/no-v-html -->
    <div :class="b('footer')">
      <e-button type="button" @click="confirm">
        {{ options?.confirmButtonText || $t('c-confirmation-modal.confirmButton') }}
      </e-button>
      <e-button :class="b('action', { cancel: true })"
                type="button"
                variant="secondary"
                @click="cancel"
      >
        {{ options?.cancelButtonText || $t('c-confirmation-modal.cancelButton') }}
      </e-button>
    </div>
  </c-modal>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { confirmation, Options } from '@/plugins/confirmation';
  import cModal from '@/components/c-modal.vue';
  import eButton from '@/elements/e-button.vue';

  // interface Setup {}

  // interface Data {}

  /**
   * Renders the confirmation modal.
   * See `src/plugins/confirmation.ts`.
   *
   * **WARNING: uses 'v-html' for the 'message'. Make sure, that the source for this data is trustworthy.**
   */
  export default defineComponent({
    name: 'c-confirmation-modal',
    components: {
      eButton,
      cModal,
    },

    // components: {},

    // props: {},
    // emits: {},

    // setup(): Setup {},
    // data(): Data {
    //   return {};
    // },

    computed: {
      show(): boolean {
        return !!confirmation.instance;
      },

      options(): Options | undefined {
        return confirmation.instance?.options;
      },

      title(): string | undefined {
        const { title } = this.options || {};

        if (title === null) {
          return undefined;
        }

        return title || this.$t('c-confirmation-modal.title');
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      confirm(): void {
        confirmation.instance?.resolve();
      },

      cancel(): void {
        confirmation.instance?.reject();
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-confirmation-modal {
    &__message {
      @include mixins.font(variables.$font-size--18, variables.$line-height--25);

      margin-bottom: variables.$spacing--50;

      a {
        @include mixins.link--primary();
      }
    }

    &__footer {
      display: grid;
      grid-gap: variables.$spacing--25;

      @include mixins.media(sm) {
        grid-template-columns: 1fr 1fr;
      }
    }

    &__action--cancel {
      @include mixins.media(sm) {
        order: -1;
      }
    }
  }
</style>
