import { Plugin } from 'vue';
import useSessionStore from '@/stores/session';
import { Product } from '@/types/product';
import { OrderEntry } from '@/types/order-entry';
import { User } from '@/types/user';
import { Mode as BarcodeScannerMode } from '@/components/c-barcode-scanner.vue';
import { Facet } from '@/types/facet';
import { CmsLink } from '@/types/cms-link';
import { Order } from '@/types/order';

export enum ListName {
  CategoryResults = 'Category results',
  SearchResults = 'Search results',
  VariantList = 'Variant list',
  SubstituteProducts = 'Substitute products',
  ProductSlider = 'Product slider',
  Favourites = 'Favourites',
  SearchSuggestions = 'Search suggestions',
  Cart = 'Cart',
  CollectiveOrder = 'Collective order',
  OrderHistory = 'Order history',
  WishList = 'Wishlist',
  CustomerAssortment = 'Customer assortment',
  ProductComparison = 'Product comparison',
}

enum EventCategory {
  Oxomi = 'OXOMI',
  BarcodeScanner = 'Barcode Scanner',
  Navigation = 'Navigation',
  MiniCart = 'Mini-Cart',
  Filter = 'Filter',
  ProductLink = 'Product Link',
  ProductDetail = 'Product detail',
  Cart = 'Cart',
  WishList = 'Shopping list 2.0',
}

interface Options {
  debug?: boolean;
}

interface ListItem {
  item_id: string;
  item_name?: string;
  currency?: string | null;
  index?: number | null;
  price?: number | null;
  quantity?: number | null;
  item_category?: string | null;
  item_list_name?: string;
}

interface AddPaymentInfoPayload {
  value: number | null;
  paymentType: string;
  items: ListItem[];
}

interface AddShippingInfoPayload {
  value: number | null;
  shippingTier: string;
  items: ListItem[];
}

export interface Gtm {
  push(payload: Record<string, unknown>): void;
  pushAddToCart(item: ListItem, list?: ListName): void;
  pushLogin(): void;
  pushSignUp(): void;
  pushViewItemList(items: ListItem[], list: ListName): void;
  pushViewItem(item: ListItem): void;
  pushViewCart(items: ListItem[], value: number | null): void;
  pushRemoveFromCart(items: ListItem[], value: number): void;
  pushBeginCheckout(items: ListItem[], value: number | null): void;
  pushSelectItem(product: Product, list: ListName): void;
  pushPurchase(order: Order): void;
  pushAddPaymentInfo(payload: AddPaymentInfoPayload): void;
  pushAddShippingInfo(payload: AddShippingInfoPayload): void;
  pushUserData(user: User): void;
  pushOpenBarcodeScanner(mode: BarcodeScannerMode, uid: string): void;
  pushSelectCategory(categoryTitle: string): void;
  pushMiniCartState(action: 'open' | 'close'): void;
  pushSelectFilter(facet: Facet, value: string): void;
  pushPrintDetail(product: Product): void;
  pushFooterLink(link: CmsLink): void;
  pushCartUpload(type: string): void;
  pushViewPromotion(promotionName: string, creativeName: string, index: number): void;
  pushSelectPromotion(promotionName: string, creativeName: string, index: number): void;
  pushSearch(searchTerm: string): void;
  pushHistoryChange(): void;
  debug(enable: boolean): void;
  mapProductToListItem(product: Product): ListItem;
  mapOrderEntryToListItem(orderEntry: OrderEntry): ListItem;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { dataLayer: Record<string, unknown>[] }
}

const userEvent = 'userAction';

/**
 * Adds a Google Tag Manager interface to the app.
 */
const plugin: Plugin = {
  /**
   * Install method of the Google Tag Manager plugin.
   */
  install(app, options: Options = {}) {
    let { debug } = options;

    function mapProductToListItem(product: Product): ListItem {
      return {
        item_id: product.baseProduct ?? product.code,
        item_name: product.name,
        price: product.price?.value,
        currency: product.price?.currencyIso || useSessionStore().getCurrencyIsoCode,
      };
    }

    /**
     * Push a new event to the dataLayer.
     */
    function push(payload: Record<string, unknown>): void {
      if (window.dataLayer && window.dataLayer.push) {
        window.dataLayer.push({ ecommerce: null }); // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
        window.dataLayer.push(payload);

        // Log if debug and development mode are active
        if (import.meta.env.MODE !== 'production') {
          if (debug) {
            console.group('GTM debug'); // eslint-disable-line no-console
            console.log('payload', payload); // eslint-disable-line no-console
            console.log('dataLayer', window.dataLayer); // eslint-disable-line no-console
            console.groupEnd(); // eslint-disable-line no-console
          }
        }
      } else {
        throw new Error('Google Tag Manager dataLayer is not available!');
      }
    }

    function mapOrderEntryToListItem(orderEntry: OrderEntry): ListItem {
      return {
        ...mapProductToListItem(orderEntry.product),
        price: orderEntry.basePrice?.value || null,
        quantity: orderEntry.quantity,
      };
    }

    function trackOxomiClicks(event: Event): void {
      const target = event.target as HTMLElement;
      let link;

      if (!target) {
        return;
      }

      const parentTarget = target.parentElement;
      const targetIsLink = target.classList.contains('oxomi-link');
      const parentTargetIsLink = parentTarget?.classList.contains('oxomi-link');

      if (targetIsLink) {
        link = target;
      } else if (parentTargetIsLink) {
        link = parentTarget;
      }

      if (link) {
        const onclickAttribute = link.getAttribute('onclick');
        const idSearch = onclickAttribute?.match(/('(.*?)'|"(.*?)")/g);
        const id = idSearch && idSearch[0] ? idSearch[0].replace(/'|"/g, '') : '';
        const title = link.getAttribute('title') || link.getAttribute('src');

        push({
          eventCategory: EventCategory.Oxomi,
          action: 'OXOMI-Click',
          eventLabel: `OXOMI-${title} (${id})`,
          event: userEvent,
        });
      }
    }

    document.addEventListener('click', trackOxomiClicks);

    const api: Gtm = {
      mapProductToListItem,
      mapOrderEntryToListItem,
      push,

      /**
       * Pushes `purchase`-event to the dataLayer.
       */
      pushPurchase(order: Order) {
        push({
          event: 'purchase',
          ecommerce: {
            transaction_id: order.code,
            currency: useSessionStore().getCurrencyIsoCode,
            value: order.totalPrice.value,
            shipping: order.deliveryCost?.value || null,
            tax: order.totalTax?.value || null,
            coupon: order.appliedVouchers?.length ? order.appliedVouchers.join('|') : null,
            items: order.entries.map(mapOrderEntryToListItem),
          },
        });
      },

      /**
       * Pushes `add_payment_info`-event to the dataLayer.
       */
      pushAddPaymentInfo(payload: AddPaymentInfoPayload) {
        push({
          event: 'add_payment_info',
          ecommerce: {
            ...payload,
            currency: useSessionStore().getCurrencyIsoCode,
          },
        });
      },

      /**
       * Pushes `add_shipping_info`-event to the dataLayer.
       */
      pushAddShippingInfo(payload: AddShippingInfoPayload) {
        push({
          event: 'add_shipping_info',
          ecommerce: {
            ...payload,
            currency: useSessionStore().getCurrencyIsoCode,
          },
        });
      },

      /**
       * Pushes `remove_from_cart`-event to the dataLayer.
       */
      pushRemoveFromCart(items: ListItem[], value: number) {
        push({
          event: 'remove_from_cart',
          ecommerce: {
            items,
            value,
            currency: useSessionStore().getCurrencyIsoCode,
          },
        });
      },

      /**
       * Pushes `view_cart`-event to the dataLayer.
       */
      pushViewCart(items: ListItem[], value: number | null) {
        push({
          event: 'view_cart',
          ecommerce: {
            items: items.map((item, index) => ({
              ...item,
              index: index + 1,
            })),
            value,
            currency: useSessionStore().getCurrencyIsoCode,
          },
        });
      },

      /**
       * Pushes `begin_checkout`-event to the dataLayer.
       */
      pushBeginCheckout(items: ListItem[], value: number | null) {
        push({
          event: 'begin_checkout',
          ecommerce: {
            items: items.map((item, index) => ({
              ...item,
              index: index + 1,
            })),
            value,
            currency: useSessionStore().getCurrencyIsoCode,
          },
        });
      },

      /**
       * Pushes `view_item`-event to the dataLayer.
       */
      pushViewItem(item: ListItem) {
        push({
          event: 'view_item',
          ecommerce: {
            items: [item],
            value: item.price,
            currency: item.currency,
          },
        });
      },

      /**
       * Pushes `view_item_list`-event to the dataLayer.
       */
      pushViewItemList(items: ListItem[], list: ListName) {
        push({
          event: 'view_item_list',
          ecommerce: {
            item_list_name: list,
            items: items.map((item, index) => ({
              ...item,
              list,
              index: index + 1,
            })),
          },
        });
      },

      /**
       * Pushes `select_item`-event to the dataLayer.
       */
      pushSelectItem(product: Product, list: ListName) {
        push({
          event: 'select_item',
          ecommerce: {
            item_list_name: list,
            items: [mapProductToListItem(product)],
          },
        });
      },

      /**
       * Pushes `login`-event to the dataLayer.
       */
      pushLogin() {
        push({
          event: 'login',
        });
      },

      /**
       * Pushes `sign_up`-event to the dataLayer.
       */
      pushSignUp() {
        push({
          event: 'sign_up',
        });
      },

      /**
       * Pushes `add_to_cart`-event to the dataLayer.
       */
      pushAddToCart(item: ListItem, list?: ListName) {
        if (list) {
          item.item_list_name = list;
        }

        push({
          event: 'add_to_cart',
          ecommerce: {
            currency: useSessionStore().getCurrencyIsoCode,
            items: [item],
          },
        });
      },

      pushUserData(user: User): void {
        push({
          event: 'userData',
          userType: user.userType,
          uid: user.uid,
          customerId: user.customerId,
        });
      },

      pushOpenBarcodeScanner(mode: BarcodeScannerMode, uid: string): void {
        push({
          eventCategory: EventCategory.BarcodeScanner,
          action: `Open-Scandit-${mode}`,
          eventLabel: uid,
          event: userEvent,
        });
      },

      pushSelectCategory(categoryTitle: string): void {
        push({
          eventCategory: EventCategory.Navigation,
          action: 'Select Category',
          eventLabel: categoryTitle,
          event: userEvent,
        });
      },

      pushFooterLink(link: CmsLink): void {
        push({
          eventCategory: EventCategory.Navigation,
          action: 'Footer Link',
          eventLabel: link.url,
          event: userEvent,
        });
      },

      pushMiniCartState(action: 'open' | 'close'): void {
        push({
          eventCategory: EventCategory.MiniCart,
          action: 'Mini-Cart change',
          eventLabel: action === 'open' ? 'Mini-Cart-Open' : 'Mini-Cart-Close',
          event: userEvent,
        });
      },

      pushSelectFilter(facet: Facet, value: string): void {
        push({
          eventCategory: EventCategory.Filter,
          action: 'Select filter',
          eventLabel: `${facet.code}-${facet.name}`,
          eventValue: value,
          event: userEvent,
        });
      },

      pushPrintDetail(product: Product): void {
        push({
          eventCategory: EventCategory.ProductDetail,
          action: 'Print Product Page',
          eventLabel: `Print-${product.code}`,
          event: userEvent,
        });
      },

      pushCartUpload(type: string): void {
        push({
          eventCategory: EventCategory.Cart,
          action: 'Cart Upload',
          eventLabel: `Upload File type: ${type}`,
          event: userEvent,
        });
      },

      pushViewPromotion(promotionName: string, creativeName: string, index = 1): void {
        push({
          event: 'view_promotion',
          ecommerce: {
            creative_slot: `Slot ${index}`,
            creative_name: creativeName,
            promotion_name: promotionName,
          },
        });
      },

      pushSelectPromotion(promotionName: string, creativeName: string, index = 1): void {
        push({
          event: 'select_promotion',
          ecommerce: {
            creative_slot: `Slot ${index}`,
            creative_name: creativeName,
            promotion_name: promotionName,
          },
        });
      },

      pushSearch(searchTerm: string): void {
        push({
          event: 'search',
          search_term: searchTerm,
        });
      },

      pushHistoryChange(): void {
        push({
          event: 'historyChange',
        });
      },

      /**
       * Enable/disable debugging.
       */
      debug(enable: boolean) {
        debug = enable !== false;
      },
    };

    app.config.globalProperties.$gtm = api;
  },
};

export default plugin;
